import {
  Constant,
  Expression,
  MakeStruct,
  Ty,
  TyStackTrace,
  TC,
} from '@cotera/era';
import _ from 'lodash';

export const FFI_FUNC_NAMES = {
  TOPIC_MATCHING: '@@cotera-topic-matching',
  OPEN_LINK: '@@cotera-open-link',
  SET_ASSUMPTIONS: '@@cotera-set-assumptions',
  PAUSE_SINK: '@@cotera-pause-sink',
  SNAPSHOT: '@@cotera-snapshot',
  SINK_CURSOR: '@@cotera-set-sink-cursor',
  UUD_WRITE: '@@cotera-udd-write',
} as const;

export type FiiFuncName = (typeof FFI_FUNC_NAMES)[keyof typeof FFI_FUNC_NAMES];

export const isFiiFuncName = (x: string): x is FiiFuncName =>
  Object.values(FFI_FUNC_NAMES).includes(x as any);

export const isFFI = (ty: Ty.ExtendedAttributeType): boolean =>
  ty.tags.some(isFiiFuncName);

export const FII_REQUIREMENTS: Record<FiiFuncName, TC.RelInterfaceShorthand> = {
  '@@cotera-udd-write': {
    entity_name: [Ty.nn('string')],
    identifier: [Ty.nn('string'), Ty.nn('int')],
    options: 'any',
  },

  '@@cotera-open-link': {
    to: [Ty.nn('string')],
    label: [Ty.nn('string')],
    newTab: { allowed: [Ty.nn('boolean')], optional: true },
  },
  '@@cotera-set-assumptions': {
    entity_name: [Ty.nn('string')],
    table_name: ['string'],
    table_schema: ['string'],
    candidates: [
      Ty.a(
        Ty.s({
          column_name: 'string',
          data_type: 'string',
          is_nullable: 'boolean',
        })
      ),
    ],
  },
  '@@cotera-pause-sink': {
    entityId: ['string'],
    streamId: ['string'],
    sinkId: ['string'],
  },
  '@@cotera-snapshot': {
    entityId: [Ty.nn('string')],
    streamId: [Ty.nn('string')],
  },
  '@@cotera-set-sink-cursor': {
    detectedAt: ['timestamp'],
    coteraStableId: ['string'],
    entityId: ['string'],
    streamId: ['string'],
    sinkId: ['string'],
  },
  '@@cotera-topic-matching': {
    id: [Ty.nn('string')],
    entityName: [Ty.nn('string')],
    coteraStableId: [Ty.nn('string')],
    content: [Ty.nn('string')],
    topicVersionId: [Ty.ty('string')],
    topicExtractionPromptId: [Ty.ty('string')],
    summaryPromptId: [Ty.ty('string')],
  },
};

export const FFI_CALL = (params: {
  func: FiiFuncName;
  params: Record<string, Ty.Scalar | Expression>;
  jsStackPointer: Function;
}): Expression => {
  const reqs = FII_REQUIREMENTS[params.func];
  const exprs = _.mapValues(params.params, (x) => Expression.wrap(x));

  const tc = TC.implementsRel({
    subject: _.mapValues(exprs, (x) => x.ty),
    reqs,
  });

  if (tc.isOk()) {
    return MakeStruct({
      func: Constant(params.func),
      params: MakeStruct(params.params),
    }).tag(params.func);
  } else {
    throw TyStackTrace.fromErr({}, tc.error).toError(params);
  }
};
