import { Token } from '../lex/tokens';
import { WithMeta } from '../lex/types';

export const infixBindingPower = ([token, _meta]: WithMeta<Token>):
  | [number, number]
  | null => {
  switch (token.t) {
    case 'operator':
      switch (token.op) {
        case '|>':
          return [0, 1];
        case '??':
          return [1, 2];
        case 'and':
        case 'or':
        case 'like':
          return [2, 3];
        case '>':
        case '<':
        case '<=':
        case '>=':
        case '=':
        case '!=':
          return [4, 5];
        case '+':
        case '-':
        case '||':
          return [6, 7];
        case '*':
        case '/':
        case '^':
          return [8, 9];
        case ':':
          return [10, 11];
        default:
          return null;
      }
    default:
      return null;
  }
};

export const postfixBindingPower = ([token, _meta]: WithMeta<Token>):
  | [number, null]
  | null => {
  switch (token.t) {
    case 'grouping': {
      switch (token.op) {
        case '}':
          return [-1, null];
        case ')':
          return [-2, null];
        case ',':
          return [-3, null];
        default:
          return null;
      }
    }
    case 'operator': {
      switch (token.op) {
        case ':':
          return [-1, null];
        case '!':
          return [5, null];
        default:
          return null;
      }
    }
    case 'tag': {
      return [1, null];
    }
    default:
      return null;
  }
};
