import { Virtualizer } from '@tanstack/react-virtual';
import { Registry } from './types';
import { Relation } from '@cotera/era';
import { makeStore, StateGetter, StateSetter } from '@cotera/client/app/etc';
import { DuckDBQueryResult } from '../../etc/duckdb';
import { uniq, uniqBy } from 'lodash';

export type TableVirtualizer = {
  controller: Virtualizer<HTMLDivElement, Element>;
  paddingStart: number | undefined;
  paddingEnd: number | undefined;
};

type StoreState = {
  dataLength: number;
  totalRows: number;
  showActionsColumn: boolean;
  rel: Relation;
  registry: Registry;
  selectedRows: number[];
  inViewRows: number[];
  data?: DuckDBQueryResult;
  hiddenColumns: string[];
  sortColumns: { column: string; direction: 'asc' | 'desc' }[];
};

export const actions =
  (
    onColumnVisibilityChange: (hiddenColumns: string[]) => void,
    onSort: (
      sortColumns: { column: string; direction: 'asc' | 'desc' }[]
    ) => void,
    onTransform: (fn: (rel: Relation) => Relation) => void
  ) =>
  (set: StateSetter<StoreState>, get: StateGetter<StoreState>) => ({
    setDataLength: (dataLength: number) =>
      set(() => ({
        dataLength,
      })),
    setTotalRows: (totalRows: number) =>
      set(() => ({
        totalRows,
      })),
    toggleActionsColumn: () =>
      set((s) => ({
        showActionsColumn: !s.showActionsColumn,
      })),
    setSelectedRows: (selectedRows: number[]) =>
      set(() => ({
        selectedRows,
      })),
    setInViewRows: (rows: number[]) =>
      set(() => ({
        inViewRows: rows,
      })),
    setData(data: DuckDBQueryResult) {
      set(() => ({ data }));
    },
    hideColumn(column: string) {
      const hiddenColumns = uniq([...get().hiddenColumns, column]);

      onColumnVisibilityChange(hiddenColumns);

      set(() => ({
        hiddenColumns,
      }));
    },
    showColumn(column: string) {
      const hiddenColumns = get().hiddenColumns.filter((c) => c !== column);

      onColumnVisibilityChange(hiddenColumns);

      set(() => ({
        hiddenColumns,
      }));
    },
    addSort: (column: string, direction: 'asc' | 'desc') => {
      const sortColumns = uniqBy(
        [
          ...get().sortColumns.filter((x) => x.column !== column),
          { column, direction },
        ],
        'column'
      );

      onSort(sortColumns);

      set(() => ({
        sortColumns,
      }));
    },
    removeSort: (column: string) => {
      const sortColumns = get().sortColumns.filter((s) => s.column !== column);

      onSort(sortColumns);

      set(() => ({
        sortColumns,
      }));
    },
    transform: onTransform,
  });

type Actions = ReturnType<ReturnType<typeof actions>>;

export const { hook: useDataGridStore, provider: DataGridProvider } = makeStore<
  StoreState,
  Actions
>();
