import {
  AST,
  EraCache,
  From,
  Relation,
  SQL,
  Ty,
  ARTIFACT_SIZE,
} from '@cotera/era';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@cotera/client/app/hooks/query-cache-keys';
import { HandlerOpts } from './handlers';
import { CoteraDuckDB } from '../db';
import range from 'lodash/range';
import { err, ok } from 'neverthrow';
import { CancelablePromise } from '../cancelable';
import { TenantedClient } from '@cotera/api';
import { config } from '@cotera/client/config/config';
import { Assert, assertOk } from '@cotera/utilities';
import mapValues from 'lodash/mapValues';
import { DateTime } from 'luxon';
import {
  useAppData,
  useTenantedClient,
  useWhoami,
} from '@cotera/client/app/stores/org';
import { useSuspenseQuery } from '@tanstack/react-query';
import { DataProvider } from './fn.type';

const POLL_INTERVAL_MS = 1000;
const MAX_POLL_ATTEMPTS = 15 * 60;
const MAX_CACHE_TIME_IN_HOURS = 24;

type Client = Pick<
  TenantedClient['artifacts'],
  'create' | 'getRequestById' | 'usableCache'
>;

type CacheResult = EraCache.ServeFromCacheResult<
  { artifactId: string },
  { message: string }
>;

const ERA_CACHE_POLICY: EraCache.CachePolicy = {
  artifactSize: ARTIFACT_SIZE,
  isFresh: ({ artifactCreatedAt }): boolean => {
    return (
      Math.abs(
        DateTime.now().diff(DateTime.fromJSDate(artifactCreatedAt)).as('hours')
      ) < MAX_CACHE_TIME_IN_HOURS
    );
  },
};

const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const cachedReqToIR = async (
  {
    signature,
    artifactId,
  }: {
    signature: { [name: string]: Ty.ExtendedAttributeType };
    artifactId: string;
  },
  { db, orgId }: { db: CoteraDuckDB; orgId: string }
): Promise<AST.RelIR> => {
  await db.registerFileURL(
    `${artifactId}.parquet`,
    `${config.apiUrl}/assets/artifacts/${orgId}/${artifactId}.parquet${
      config.demo ? '?x-cotera-demo=true' : ''
    }`
  );
  const file = From({ uri: `${artifactId}.parquet`, attributes: signature });
  return file.ir();
};

const waitForArtifact =
  (
    queryClient: QueryClient,
    client: Client,
    initedDb: Promise<CoteraDuckDB>,
    orgId: string
  ) =>
  async (props: { artifactId: string; orgId: string }, opts?: HandlerOpts) => {
    const db = await initedDb;
    const { artifactId } = props;

    for (const _ of range(MAX_POLL_ATTEMPTS)) {
      if (opts?.abort?.signal?.aborted) {
        return err({ message: 'Aborted' });
      }

      const response = await queryClient.fetchQuery({
        queryKey: queryKeys.artifacts.requestById({
          orgId,
          reqId: artifactId,
        }),
        queryFn: () => client.getRequestById({ id: artifactId }),
      });

      if (response.isOk() && response.value !== null) {
        const { fullfillment, failure } = response.value;
        if (fullfillment !== null) {
          return ok({
            ir: await cachedReqToIR(
              { signature: fullfillment.signature, artifactId },
              { db, orgId }
            ),
            meta: { artifactId },
          });
        }

        if (failure !== null) {
          return err({ message: failure.errorMessage });
        }
      } else if (response.isErr()) {
        return err({ message: response.error.err.errorType });
      }

      await sleep(POLL_INTERVAL_MS);
    }

    return err({
      message: `Poll Attempts Exceeded for Artifact "${artifactId}"`,
    });
  };

export const useUseableArtifactCache: DataProvider<
  {},
  EraCache.ExistingCache<{ artifactId: string }, { message: string }>
> = (_, opts) => {
  const queryClient = useQueryClient();
  const api = useTenantedClient();
  const orgId = useWhoami((x) => x.org.id);
  const initedDb = useAppData((s) => s.initedDb);

  return useSuspenseQuery({
    queryFn: async () => {
      const db = await initedDb;
      const data = assertOk(await api.artifacts.usableCache({}));

      return mapValues(data, ({ id: artifactId, status, createdAt }) => {
        const fetch = async (): Promise<CacheResult> => {
          if (status.t === 'ready') {
            return ok({
              ir: await cachedReqToIR(
                { artifactId, signature: status.signature },
                { db, orgId }
              ),
              meta: { artifactId },
            });
          }

          return waitForArtifact(
            queryClient,
            api.artifacts,
            initedDb,
            orgId
          )({ artifactId, orgId }, { abort: opts?.abort });
        };

        const complete = status.t === 'ready' ? status.complete : false;
        return { fetch, createdAt, complete, meta: { artifactId } };
      });
    },
    queryKey: queryKeys.artifacts.usableCache({ orgId }),
  });
};

export const useGetOrCreateArtifact: DataProvider<
  {
    rel: Relation;
    onArtifact?: (props: {
      id: string | null;
      sourceHash: string;
      rel: Relation;
    }) => void;
  },
  CacheResult
> = (props, opts) => {
  const existingCache = useUseableArtifactCache({ opts, props: {} });
  const orgId = useWhoami((x) => x.org.id);
  const api = useTenantedClient();
  const queryClient = useQueryClient();
  const initedDb = useAppData((s) => s.initedDb);

  return useSuspenseQuery({
    queryFn: async () => {
      const { rel } = props;

      const ir = rel.ir();
      const mode = Assert.assertOk(SQL.originForIR(ir));

      // Return early if it's a file that we can run in the FE
      if (mode === 'file' || mode === null) {
        return ok({ ir, meta: null });
      }

      // Send the results to the BE
      const create = async (ir: AST.RelFR): Promise<CacheResult> => {
        const { id } = Assert.assertOk(await api.artifacts.create({ ast: ir }));

        const result = await waitForArtifact(
          queryClient,
          api.artifacts,
          initedDb,
          orgId
        )({ artifactId: id, orgId }, opts);

        if (result.isOk()) {
          props.onArtifact?.({
            id: result.value.meta?.artifactId ?? null,
            sourceHash: rel.sqlHash(),
            rel: Relation.wrap(result.value.ir),
          });
        }

        return result;
      };

      return new CancelablePromise(
        EraCache.serve<{ artifactId: string }, { message: string }>({
          ir,
          existingCache: existingCache.data,
          create,
          policy: ERA_CACHE_POLICY,
        }),
        { controller: opts?.abort }
      );
    },
    queryKey: queryKeys.artifacts.forRel({ rel: props.rel, orgId }),
  });
};
