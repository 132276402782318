import { AST } from '../../ast';
import { Expression } from '../../builder/expression';

export const GROUPING = ['(', ')', '[', ']', '{', '}', ','] as const;

export type Grouping = (typeof GROUPING)[number];

export const isGrouping = (x: string): x is Grouping =>
  GROUPING.includes(x as any);

export const OPERATORS = [
  '|>',
  ':',
  '??',
  '||',
  'like',
  ...AST.MATH_FUNCTIONS,
  ...AST.CONNECTIVE_FUNCTIONS,
  ...AST.COMPARISON_FUNCTIONS,
  '!',
  '?',
] as const;

export type Operator = (typeof OPERATORS)[number];

export const isOperator = (x: string): x is Operator =>
  OPERATORS.includes(x as any);

export type TagToken = {
  readonly t: 'tag';
  readonly tag: string;
};

export type GroupingToken = {
  readonly t: 'grouping';
  readonly op: Grouping;
};

export type SymbolToken = {
  readonly t: 'symbol';
  readonly sym: string;
};

export type OperatorToken = {
  readonly t: 'operator';
  readonly op: Operator;
};

export type IdentToken = {
  readonly t: 'ident';
  readonly name: string;
};

export type LiteralToken = {
  readonly t: 'literal';
  readonly val: string | boolean | number | Date;
};

export type RawExpressionToken = {
  readonly t: 'raw-expression';
  readonly val: Expression;
};

export type WhiteSpaceToken = {
  readonly t: 'white-space';
  readonly space: string;
};

export type UnknownToken = {
  readonly t: 'unknown';
  readonly found: string;
};

export type NongroupingToken =
  | SymbolToken
  | OperatorToken
  | IdentToken
  | LiteralToken
  | RawExpressionToken
  | WhiteSpaceToken
  | TagToken
  | UnknownToken;

export type Token = GroupingToken | NongroupingToken;

export const op = (opToken: string): OperatorToken => {
  if (isOperator(opToken)) {
    return { t: 'operator', op: opToken };
  } else {
    const error = new Error(`"${opToken}" is not a valid operator`);
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, op);
    }
    throw error;
  }
};

export const group = (opToken: string): GroupingToken => {
  if (isGrouping(opToken)) {
    return { t: 'grouping', op: opToken };
  } else {
    const error = new Error(`"${opToken}" is not a valid grouping`);
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, op);
    }
    throw error;
  }
};

export function tag(tag: string): TagToken {
  return { t: 'tag', tag };
}

export function unknown(found: string): UnknownToken {
  return { t: 'unknown', found };
}
export function lit(val: string | number | boolean | Date): LiteralToken {
  return { t: 'literal', val };
}
export function ident(name: string): IdentToken {
  return { t: 'ident', name };
}
export function ws(space: string): WhiteSpaceToken {
  return { t: 'white-space', space };
}
export function rawExpr(val: Expression): RawExpressionToken {
  return { t: 'raw-expression', val };
}
export function sym(sym: string): SymbolToken {
  return { t: 'symbol', sym };
}
