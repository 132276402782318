import { Expression } from './expression';
import { OverClause as FluentOverClause } from './relation';
import _ from 'lodash';
import { Case, IsNaN, Not } from './utilities';
import { SetRequired } from 'type-fest';
import { Ty } from '../ty';
import { AST } from '../ast';
import { WINDOW_FUNCTION_FRAME_RULES } from '../type-checker/expr/function-call';
import { Freeze } from '../utils';
import { MacroChildren } from '../ast/base';

// WINDOW FUNCTIONS

const WindowFunction = (props: {
  op: AST.WindowOp;
  args?: (Ty.Scalar | Expression)[];
  over?: FluentOverClause;
  jsStackPointer?: Function;
}): Expression => {
  const {
    op,
    args = [],
    over: { partitionBy = [], orderBy = [], frame } = {},
  } = props;
  const argsExprs = args.map((x) => Expression.wrap(x));

  const partitionByArr = Freeze.isReadonlyArray(partitionBy)
    ? partitionBy
    : [partitionBy];
  const orderByArr = Freeze.isReadonlyArray(orderBy) ? orderBy : [orderBy];

  const partitionByAst = partitionByArr.map((x) => x.ast);
  const orderByAst = orderByArr.map((x) => {
    if (x instanceof Expression) {
      return { expr: x.ast, direction: 'asc' as const };
    }
    return { expr: x.expr.ast, direction: x.direction };
  });

  const defaultFrame: AST.WindowFrame | null =
    orderByAst.length > 0 && WINDOW_FUNCTION_FRAME_RULES[op] === 'required'
      ? { preceding: 'unbounded', following: 'unbounded' }
      : null;

  const ast: AST._Window<MacroChildren> = {
    t: 'window',
    frame: frame ?? defaultFrame,
    op,
    args: argsExprs.map((arg) => arg.ast),
    over: {
      partitionBy: partitionByAst,
      orderBy: orderByAst,
    },
  };

  return Expression.fromAst(ast, {
    jsStackPointer: props.jsStackPointer ?? WindowFunction,
  });
};

export const FirstValueOver = (
  expr: Ty.Scalar | Expression,
  opts: SetRequired<FluentOverClause, 'orderBy'>
): Expression =>
  WindowFunction({ op: 'first_value', args: [expr], over: opts });

export const LastValueOver = (
  expr: Ty.Scalar | Expression,
  opts: FluentOverClause
): Expression => WindowFunction({ op: 'last_value', args: [expr], over: opts });

export const RankOver = (
  over: SetRequired<Omit<FluentOverClause, 'frame'>, 'orderBy'>
): Expression => WindowFunction({ op: 'rank', over, jsStackPointer: RankOver });

export const RowNumberOver = (
  over: SetRequired<Omit<FluentOverClause, 'frame'>, 'orderBy'>
): Expression =>
  WindowFunction({ op: 'row_number', over, jsStackPointer: RowNumberOver });

export const DenseRankOver = (
  over: SetRequired<Omit<FluentOverClause, 'frame'>, 'orderBy'>
): Expression =>
  WindowFunction({ op: 'dense_rank', over, jsStackPointer: DenseRankOver });

export const PercentRankOver = (
  over: SetRequired<Omit<FluentOverClause, 'frame'>, 'orderBy'>
): Expression =>
  WindowFunction({ op: 'percent_rank', over, jsStackPointer: PercentRankOver });

export const CountOver = (
  expr: Ty.Scalar | Expression,
  over?: FluentOverClause
): Expression =>
  WindowFunction({ op: 'count', args: [expr], over }).cast('int');

export const AvgOver = (
  expr: number | Expression,
  over?: FluentOverClause
): Expression =>
  WindowFunction({ op: 'avg', args: [expr], over }).cast('float');

export const CorrOver = (
  x: number | Expression,
  y: number | Expression,
  over?: Omit<FluentOverClause, 'frame'>
): Expression => {
  const corr = WindowFunction({ op: 'corr', args: [x, y], over });
  // Warehouses don't agree if corr(1, 1) is `null` or `NaN` so normalize to
  // `null` (we also can't use `nullif` on NaN because NaN != NaN in some (but
  // not all) warehouses)
  return Case([{ when: Not(IsNaN(corr)), then: corr }]);
};

export const SumOver = (
  expr: number | Expression,
  over?: FluentOverClause
): Expression =>
  WindowFunction({ op: 'sum', args: [expr], over }).cast('float');

export const LeadOver = (
  expr: Ty.Scalar | Expression,
  opts: SetRequired<Omit<FluentOverClause, 'frame'>, 'orderBy'>
): Expression => WindowFunction({ op: 'lead', args: [expr], over: opts });

export const LagOver = (
  expr: Ty.Scalar | Expression,
  opts: SetRequired<Omit<FluentOverClause, 'frame'>, 'orderBy'>
): Expression => WindowFunction({ op: 'lag', args: [expr], over: opts });

export const NtileOver = (
  expr: number | Expression,
  opts: SetRequired<Omit<FluentOverClause, 'frame'>, 'orderBy'>
): Expression =>
  WindowFunction({ op: 'ntile', args: [expr], over: opts }).cast('int');

export const MinOver = (
  expr: number | Expression,
  opts?: Omit<FluentOverClause, 'orderBy'>
): Expression => WindowFunction({ op: 'min', args: [expr], over: opts });

export const MaxOver = (
  expr: number | Expression,
  opts?: Omit<FluentOverClause, 'orderBy'>
): Expression => WindowFunction({ op: 'max', args: [expr], over: opts });

export const StringAggOver = (
  expr: string | Expression,
  opts?: FluentOverClause
): Expression => WindowFunction({ op: 'string_agg', args: [expr], over: opts });
