import { Section } from '@cotera/client/app/components';
import { Text } from '@cotera/client/app/components/ui';
import {
  ChildrenProps,
  classNames,
  ColorScheme,
} from '@cotera/client/app/components/utils';
import { makeStore, StateSetter } from '@cotera/client/app/etc';
import React, { forwardRef } from 'react';

type State = {
  full: boolean;
};

const actions =
  (ref: React.RefObject<HTMLDivElement>) => (set: StateSetter<State>) => ({
    toggleFull: () => set((state) => ({ full: !state.full })),
    getHeight: () => {
      if (ref.current) {
        return ref.current.parentElement?.offsetHeight ?? 0;
      }
      return 0;
    },
  });

type Actions = ReturnType<ReturnType<typeof actions>>;

export const { hook: useNodeCard, provider: Provider } = makeStore<
  State,
  Actions
>();

export const NodeCard: React.FC<
  ChildrenProps & {
    name: string;
    from?: string;
  }
> = ({ children, name, from }) => {
  const measureRef = React.useRef<HTMLDivElement>(null);

  return (
    <Provider state={{ full: false }} actions={actions(measureRef)}>
      <Inner ref={measureRef} from={from} name={name}>
        {children}
      </Inner>
    </Provider>
  );
};

const Inner = forwardRef<
  HTMLDivElement,
  ChildrenProps & { from?: string; name: string }
>(({ children, from, name }, ref) => {
  const toggleFull = useNodeCard((s) => s.actions.toggleFull);
  const full = useNodeCard((s) => s.full);
  return (
    <div
      ref={ref}
      className={classNames(
        'flex flex-col border rounded mb-4 w-full',
        !full ? 'relative' : 'absolute top-0 left-0 h-full bg-white'
      )}
    >
      <div className="flex items-center w-fit justify-between p-2 -mt-[14px] ml-4">
        {from && (
          <Text.Caption className="bg-white mr-2 px-1">
            <span className="text-xs">From:</span> {from}
          </Text.Caption>
        )}
        <Text.Span
          onClick={() => {
            toggleFull();
          }}
          ref={ref}
          className={classNames(
            'bg-white text-xs w-fit outline-none rounded px-1',
            ColorScheme.text['muted']
          )}
        >
          {name}
        </Text.Span>
      </div>

      <Section className="w-[calc(100%-2em)]">{children}</Section>
    </div>
  );
});
