import { makeStore, StateGetter, StateSetter } from '@cotera/client/app/etc';
import { Feature, TopicFeature } from '../inbox/context';

type State = {
  synced: 'synced' | 'unsynced' | 'syncing';
  features: Feature[];
  history: State[];
  pop: () => void;
  topics: {
    id: string;
    name: string;
    description: string | null;
    features: TopicFeature[];
    action?: 'upsert' | 'delete';
  }[];
  focusedFeature: Feature | null;
};

export const actions = (set: StateSetter<State>, get: StateGetter<State>) => ({
  setFocusedFeature: (feature: Feature | null) => {
    set((state) => ({ ...state, focusedFeature: feature }));
  },
  assign: (feature: Feature) => {
    set(() => ({
      //this works because there is only ever one topic in this model
      topics: [
        ...get().topics.map((topic) => ({
          ...topic,
          features: topic.features.includes(feature)
            ? topic.features
            : [...topic.features, feature],
        })),
      ],
    }));
  },
});

type Actions = ReturnType<typeof actions>;

export const { hook: useDrafts, provider: DraftProvider } = makeStore<
  State,
  Actions
>();
