import { EraQL, Expression, Relation, Ty } from '@cotera/era';
import { useState } from 'react';

export type DraftSqlExpression = {
  readonly name: string;
  readonly sql: string;
};

export type EditableSqlExpression = DraftSqlExpression & {
  readonly key: string | number;
  readonly parse: EraQL.ParseRes<Expression>;
  readonly remove: () => void;
  readonly set: (x: { name?: string; sql?: string }) => void;
};

export type DraftColumns = {
  readonly drafts: readonly EditableSqlExpression[];
  readonly add: (draft?: Partial<DraftSqlExpression>) => void;
  readonly reset: () => void;
};

export const useMultiSqlExpressionBuilder = (params: {
  readonly attributes: Relation | Record<string, Expression>;
  readonly allowAggregate: boolean;
  readonly typeImplsOneOf?: (draft: {
    name: string;
  }) => readonly Ty.Shorthand[];
}): DraftColumns => {
  const [state, setState] = useState<({ name: string; sql: string } | null)[]>(
    []
  );

  const set = (key: number, draft: Partial<DraftSqlExpression> | null) =>
    setState((x) =>
      x.map((prev, i) => (i === key ? merge(prev, draft) : prev))
    );

  const add = (draft?: Partial<DraftSqlExpression>) =>
    setState((x) => [...x, merge({ name: '', sql: '' }, draft ?? {})]);

  const reset = () => setState([]);

  const drafts: EditableSqlExpression[] = state
    .map((x, i) => [x, i] as const)
    .map(([draft, key]): EditableSqlExpression | null => {
      if (draft === null) {
        return null;
      }

      const typeImplsOneOf = params.typeImplsOneOf?.(draft);

      const attributes =
        params.attributes instanceof Relation
          ? params.attributes.ref('from').star()
          : params.attributes;

      const parse = EraQL.compileExprQl({
        sql: draft.sql,
        attributes,
        typeImplsOneOf,
        allowAggregate: params.allowAggregate,
      });

      return {
        ...draft,
        key,
        parse,
        remove: () => set(key, null),
        set: (x) => set(key, x),
      };
    })
    .filter((x) => x !== null);

  return { drafts, add, reset };
};

const merge = (
  prev: null | DraftSqlExpression,
  next: null | Partial<DraftSqlExpression>
): DraftSqlExpression | null => {
  if (next === null) {
    return null;
  }

  return {
    name: next.name ?? prev?.name ?? '',
    sql: next.sql ?? prev?.sql ?? '',
  };
};
