import { Ty } from '../ty';
import { MacroChildren, MacroVarReplacements } from './base';
import { _Chart } from './chart';
import { ExprFR, _ExprVar } from './expr';
import { RelFR, _RelVar } from './rel';
export type { _Chart } from './chart';

export const MARKUP_ICONS = [
  'arrow-trending-up',
  'arrow-trending-down',
  'bars-4',
  'battery-100',
  'briefcase',
  'calculator',
  'check',
  'map',
  'variable',
  'bell-alert',
  'bolt',
  'bar-chart',
  'pie-chart',
  'chat-bubble',
  'clipboard',
  'cube',
  'dollar',
  'lightbulb',
  'link',
  'funnel',
  'megaphone',
  'newspaper',
  'paper-airplane',
  'identification',
  'trophy',
  'exclamation-triangle',
  'shield-exclamation',
  'user-plus',
  'exclamation-circle',
  'x-circle',
  'play-circle',
  'gift',
  'command-line',
] as const;

export type MuMacroHandle = {
  readonly scope: string;
  readonly macro: Mu;
};

export type MarkupIcon = (typeof MARKUP_ICONS)[number];

export type _MacroApplyVarsToMarkup = {
  readonly t: 'macro-apply-vars-to-markup';
  readonly vars: MacroVarReplacements<MacroChildren>;
  readonly scope: string;
  readonly section: Mu;
  readonly displayName: string | null;
};

export type _MarkupVar = {
  readonly t: 'markup-var';
  readonly identifier: string;
  readonly scope: string;
};

export type _MacroMarkupCase = {
  readonly t: 'macro-section-case';
  readonly cases: readonly {
    readonly when: ExprFR;
    readonly then: Mu;
  }[];
  readonly else: Mu;
};

export type _Text = {
  readonly t: 'text';
  readonly text: ExprFR;
  readonly modifier: 'none' | 'start-case';
};

export type _Image = {
  readonly t: 'image';
  readonly uri: string;
  readonly description: string;
  readonly align: 'left' | 'right' | 'center';
  readonly size: 'lg' | 'md' | 'sm';
};

export type _Tabs = {
  readonly t: 'tabs';
  readonly style: 'tabs' | 'select';
  readonly tabs: { readonly title: string; readonly section: Mu }[];
};

export const AXIS_SCALE_OPTIONS = [
  'linear',
  'day',
  'month',
  'year',
  'millisecond',
  'second',
  'minute',
  'hour',
  'point',
] as const;

export const VALUE_FORMAT_OPTIONS = [
  'none',
  'day',
  'month',
  'year',
  'millisecond',
  'second',
  'minute',
  'hour',
  '%',
  'number',
] as const;

export type ChartFormat = Record<
  string,
  (typeof VALUE_FORMAT_OPTIONS)[number] | string
>;

export type _Page = {
  readonly t: 'page';
  readonly params: readonly string[];
  readonly config: { readonly cache: Record<string, string[]> };
  readonly body: MuMacroHandle;
};

export type _Header = {
  readonly t: 'header';
  readonly title: ExprFR;
  readonly caption: ExprFR;
};

export type _MenuItem = {
  readonly title: string;
  readonly slug: string;
  readonly icon?: MarkupIcon | null;
};

export type _Block = {
  readonly t: 'block';
  readonly title: ExprFR;
  readonly sections: readonly Mu[];
  readonly border: boolean;
};

export type _Sections = {
  readonly t: 'sections';
  readonly sections: readonly Mu[];
  readonly config: { t: 'sections' | 'row' } | { t: 'grid'; columns: number };
};

export type _Width = {
  readonly t: 'width';
  readonly size: '2/3' | '1/3' | '1/2';
  readonly section: Mu;
};

export type _Divider = {
  readonly t: 'divider';
  readonly text: ExprFR;
};

export type _Stat = {
  readonly config: {
    readonly title: ExprFR | null;
    readonly info: ExprFR | null;
    readonly unit: ExprFR | null;
    readonly style: ExprFR | null;
    readonly caption: ExprFR | null;
  };
  readonly rel: RelFR;
};

export type _Stats = {
  readonly t: 'stats';
  readonly stats: readonly _Stat[];
  readonly caption: ExprFR | null;
};

export type _Callout = {
  readonly t: 'callout';
  readonly rels: readonly RelFR[];
};

export type _Insights = {
  readonly t: 'insights';
  readonly rel: RelFR;
  readonly config: {
    readonly title: string | null;
    readonly wrap: boolean;
  };
};

export type _Noop = {
  readonly t: 'noop';
};

export type _ForEach = {
  readonly t: 'for-each';
  readonly rel: RelFR;
  readonly body: MuMacroHandle;
};

export type _UiState = {
  readonly t: 'ui-state';
  readonly url: {
    readonly params: readonly string[];
    readonly path: readonly (string | { readonly name: string })[] | null;
  };
  readonly defaults: {
    readonly exprs: { readonly [name: string]: ExprFR };
    readonly rels: { readonly [name: string]: RelFR };
  };
  readonly body: MuMacroHandle;
};

export type FilterOperators =
  | 'equals'
  | 'not equals'
  | 'less than'
  | 'greater than'
  | 'one-of'
  | 'between';

export type _RelVarControlsV2 = {
  readonly t: 'rel-controls-v2';
  readonly var: _RelVar<MacroChildren>;
  readonly config:
    | {
        readonly t:
          | 'defintion-picker'
          | 'editable-table'
          | 'file-picker'
          | 'semantic-search';
      }
    | {
        readonly t: 'slice-builder';
        readonly quickFilters: {
          t: Ty.PrimitiveAttributeType;
          left: string;
          right: unknown;
          operator: FilterOperators;
        }[];
      };
};

export type _ExprControlsV2 = {
  readonly t: 'expr-controls-v2';
  readonly var: _ExprVar<MacroChildren>;
  readonly label: boolean;
  readonly config:
    | {
        readonly t: 'picklist';
        readonly options: readonly string[];
        readonly display: 'toggle' | 'picklist' | 'tab-selector' | 'nav';
      }
    | {
        readonly t: 'pickfrom';
        readonly rel: RelFR;
        readonly display: 'toggle' | 'picklist' | 'tab-selector' | 'nav';
      }
    | null;
};

export type Mu =
  | _Noop
  | _Image
  | _Text
  | _Divider
  | _Block
  | _Tabs
  | _Stats
  | _Callout
  | _Sections
  | _Width
  | _Chart
  | _Header
  | _ForEach
  | _Page
  | _RelVarControlsV2
  | _ExprControlsV2
  | _Insights
  | _UiState
  | _MacroApplyVarsToMarkup
  | _MarkupVar
  | _MacroMarkupCase;

export type _App = {
  readonly t: 'app';
  readonly title: string; //this should never be an expression
  readonly icon: MarkupIcon;
  readonly pages: {
    readonly [slug: string]: _Page;
  };
  readonly menu: readonly _MenuItem[];
  readonly context: string | null;
};
