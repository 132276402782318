import { makeStore, StateGetter, StateSetter } from '@cotera/client/app/etc';
import { uniqBy } from 'lodash';
import { v4 } from 'uuid';

export type Feature = {
  featureId: string;
  content: string;
  documentCount: number;
  ignored: boolean;
  topics: {
    id: string | null;
    name: string;
  }[];
};

export type TopicFeature = {
  featureId: string;
  content: string;
  documentCount: number;
};

export type State = {
  searchLoading?: boolean;
  focusedFeature: Feature | null;
  topics: {
    id: string;
    name: string;
    description: string | null;
    features: TopicFeature[];
    action?: 'upsert' | 'delete';
  }[];
  features: Feature[];
  history: State[];
  pop: () => void;
  synced: 'synced' | 'unsynced' | 'syncing';
};

export const actions = (set: StateSetter<State>, get: StateGetter<State>) => ({
  assign: (
    topicId: string,
    fromTopicId: string | null,
    ...featureIds: string[]
  ) => {
    const { topics, features } = get();

    if (fromTopicId === topicId) {
      return;
    }

    const newFeatures: Feature[] = features.map((feature) => {
      if (featureIds.includes(feature.featureId)) {
        return {
          ...feature,
          topics: uniqBy(
            [
              ...feature.topics,
              {
                id: topicId,
                name: topics.find((t) => t.id === topicId)!.name,
              },
            ],
            'name'
          ),
        };
      }
      return feature;
    });

    const newTopics = topics.map((topic) => {
      if (topic.id === topicId) {
        return {
          ...topic,
          features: uniqBy(
            [
              ...topic.features,
              ...newFeatures.filter((f) => featureIds.includes(f.featureId)),
            ],
            'featureId'
          ),
        };
      }

      return topic;
    });

    set(() => ({ topics: newTopics, features: newFeatures }));
  },
  remove: (topicId: string, featureId: string) => {
    const { topics, features } = get();

    const newTopics = topics.map((topic) =>
      topic.id === topicId
        ? {
            ...topic,
            features: topic.features.filter((f) => f.featureId !== featureId),
          }
        : topic
    );

    const newFeatures = uniqBy(
      features.map((feature) =>
        feature.featureId === featureId
          ? {
              ...feature,
              topics: feature.topics.filter((t) => t.id !== topicId),
            }
          : feature
      ),
      'featureId'
    );

    set(() => ({ topics: newTopics, features: newFeatures }));
  },
  ignoreFeature: (featureId: string) => {
    const { topics, features } = get();

    const newTopics = topics.map((topic) => ({
      ...topic,
      features: topic.features.map((f) => {
        if (f.featureId === featureId) {
          return {
            ...f,
            ignored: true,
          };
        }
        return f;
      }),
    }));

    const newFeatures = features.map((feature) => {
      if (feature.featureId === featureId) {
        return {
          ...feature,
          ignored: true,
        };
      }
      return feature;
    });

    set(() => ({ topics: newTopics, features: newFeatures }));
  },
  setFocusedFeature: (feature: Feature | null) => {
    set(() => ({ focusedFeature: feature }));
  },
  addTopic: (
    name: string,
    description: string | null,
    featureIds: string[] = []
  ) => {
    const topics = [...get().topics];
    const newTopic = {
      id: v4(),
      name,
      description,
      features: get().features.filter((f) => featureIds.includes(f.featureId)),
    };
    topics.push(newTopic);
    set(() => ({ topics }));
  },
  updateTopic: (topicId: string, name: string, description: string | null) => {
    set((state) => ({
      topics: state.topics.map((topic) =>
        topic.id === topicId ? { ...topic, name, description } : topic
      ),
    }));
  },
  deleteTopic: (topicId: string) => {
    set((state) => ({
      topics: state.topics.map((topic) =>
        topic.id === topicId
          ? { ...topic, action: 'delete', features: [] }
          : topic
      ),
      features: state.features.map((feature) => {
        const topics = feature.topics.filter((t) => t.id !== topicId);

        return {
          ...feature,
          topics,
        };
      }),
    }));
  },
  addNewFeatures: (features: Feature[]) => {
    // Filter out the features that already exist in the store
    const newFeatures = features.filter(
      (feature) =>
        !get().features.find((f) => f.featureId === feature.featureId)
    );

    if (newFeatures.length === 0) {
      return;
    }

    // Update the state
    set((state) => ({
      // Add new features to the global features array
      features: [...state.features, ...newFeatures],

      // Update topics by adding the new features to the relevant topics
      topics: state.topics.map((topic) => {
        const featuresForTopic = newFeatures.filter((feature) =>
          feature.topics.some((x) => x.id === topic.id)
        );

        if (featuresForTopic.length > 0) {
          return {
            ...topic,
            features: [...topic.features, ...featuresForTopic],
          };
        }

        return topic;
      }),
    }));
  },
  setSearching: (searching: boolean) => {
    set(() => ({ searchLoading: searching }));
  },
});

type Actions = ReturnType<typeof actions>;

export const { hook: useDrafts, provider: DraftProvider } = makeStore<
  State,
  Actions
>();
