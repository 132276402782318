import { assertConstantString, SqlDialect } from './dialect';
import _ from 'lodash';
import { Eq, From, Or } from '../../builder';
import { sqlExprMacro, intersperse, pSqlMacro } from '../sql-ast';
import { Ty } from '../../ty';
import { AST } from '../../ast';
import { Constant } from '../../builder/utilities';
import { Expression } from '../../builder/expression';
import { Assert } from '../../utils';

export const PrimitiveAttributeTypeToPostgresType: Record<
  Ty.PrimitiveAttributeType,
  string
> = {
  string: 'text',
  int: 'integer',
  float: 'float',
  day: 'timestamptz',
  month: 'timestamptz',
  year: 'timestamptz',
  timestamp: 'timestamptz',
  boolean: 'boolean',
  super: 'jsonb',
};

const WAREHOUSE_TO_ERA_MAPPING: Record<string, Ty.Shorthand> = {
  text: 'string',
  integer: 'int',
  'double precision': 'float',
  timestamp: 'timestamp',
  timestamptz: 'timestamp',
  'timestamp with time zone': 'timestamp',
};

const tryWarehouseTypeToEraType = (
  warehouseType: string
): Ty.AttributeType | null => {
  const sh = WAREHOUSE_TO_ERA_MAPPING[warehouseType];
  if (sh !== undefined) {
    return Ty.ty(sh).ty;
  }

  return null;
};

const typeMapping = (ty: Ty.AttributeType): string => {
  if (ty.k === 'struct' || ty.k === 'array' || ty.k === 'record') {
    return 'jsonb';
  }

  if (ty.k === 'enum') {
    return PrimitiveAttributeTypeToPostgresType.string;
  }

  return PrimitiveAttributeTypeToPostgresType[ty.t];
};

const getPropertyFromStruct = (
  expr: AST.ExprIR,
  name: string | AST.ExprIR,
  wantedTy: Ty.AttributeType
) => {
  if (
    wantedTy.k === 'primitive' ||
    wantedTy.k === 'enum' ||
    wantedTy.k === 'id' ||
    wantedTy.k === 'range'
  ) {
    const field =
      typeof name === 'string' ? Constant(name, { ty: 'string' }).ir() : name;
    return sqlExprMacro`cast((${expr})->>(${field}) as ${typeMapping(
      wantedTy
    )})`;
  } else {
    return sqlExprMacro`((${expr})->'${name}')`;
  }
};

export const PostgresDialect: SqlDialect = {
  tryWarehouseTypeToEraType,
  nullLiteral: () => sqlExprMacro`null`,
  scalarLiteralOverrides: {
    string: (literal: string) => {
      if (literal === '') {
        return pSqlMacro`''::text`;
      }
      return /^[!*%><&/a-zA-Z0-9-_ .:,{}@"’'`()?=]+$/.test(literal)
        ? pSqlMacro`'${literal.replaceAll("'", "''")}'::text`
        : pSqlMacro`(${{ val: literal, t: 'param' }})::text`;
    },
  },
  supportsFileSources: false,
  quotingCharacter: '"',
  generateSeries: ({ start, stop }) => {
    return sqlExprMacro`select * from generate_series(${start.toString()}, ${stop.toString()}) as n`;
  },
  functionOverrides: {
    get_from_record: ([target, field], wanted) =>
      getPropertyFromStruct(target!, field!, wanted),
    is_numeric_string: ([arg0]) => sqlExprMacro`${arg0!} ~ '^\\d+$'`,
    array_agg: ([arg0]) =>
      sqlExprMacro`jsonb_agg(${arg0!}) filter (where (${arg0!}) is not null)`,

    date_trunc: ([arg0, arg1]) =>
      sqlExprMacro`(date_trunc('${assertConstantString(
        arg1!,
        AST.DATE_TRUNC_UNITS
      )}', ((${arg0!})::timestamptz) at time zone 'UTC') at time zone 'UTC')`,
    date_add: ([arg0, arg1, arg2]) => {
      const scalar = assertConstantString(arg2!, ['days']);
      const unit = { days: 'day' }[scalar];
      return sqlExprMacro`((${arg0!}) + ((${arg1!}) * '1 ${unit}'::interval))`;
    },
    date_part: ([arg0, arg1]) => {
      const unit = assertConstantString(arg1!, AST.DATE_PART_UNITS);
      return sqlExprMacro`date_part('${unit}', (((${arg0!})::timestamptz) at time zone 'UTC'))`;
    },
    date_diff: ([arg0, arg1, arg2]) => {
      const unit = assertConstantString(arg2!, AST.DATE_DIFF_UNITS);
      switch (unit) {
        case 'days':
          return sqlExprMacro`(extract(day from ((${arg1!}) - (${arg0!}))))::int`;
        case 'years':
          return sqlExprMacro`(extract(year from (${arg1!})) - extract(year from (${arg0!})))::int`;
        case 'seconds':
          return sqlExprMacro`(extract(EPOCH from ((${arg1!}) - (${arg0!}))))::float`;
      }
    },
    log_2: ([arg0]) => sqlExprMacro`log(2, ${arg0!})`,
    log_10: ([arg0]) => sqlExprMacro`log(10, ${arg0!})`,
    stddev_pop: ([arg0]) => sqlExprMacro`stddev_pop(${arg0!})::float`,
    stddev_samp: ([arg0]) => sqlExprMacro`stddev_samp(${arg0!})::float`,
    now: () => sqlExprMacro`now()`,
    percentile_cont: ([arg0, arg1]) =>
      sqlExprMacro`percentile_cont(${arg1!}) within group (order by (${arg0!}) asc)`,
    percentile_disc: ([arg0, arg1]) =>
      sqlExprMacro`percentile_disc(${arg1!}) within group (order by (${arg0!}) asc)`,
    sum: ([arg0]) => sqlExprMacro`sum(${arg0!})::float`,
    avg: ([arg0]) => sqlExprMacro`avg(${arg0!})::float`,
    count: ([arg0]) => sqlExprMacro`count(${arg0!})::int`,
    nan: () => sqlExprMacro`'nan'::float`,
    // In postgres Nan compares equal to NaN 🤦
    is_nan: ([arg0]) => sqlExprMacro`((${arg0!}) = 'nan'::float)`,
    cosine_distance: ([_arg0, _arg1]) => {
      throw new Error(
        `Function 'cosine_distance' is not implemented for the Redshift dialect.`
      );
    },
  },
  typeMapping,
  placeholder({ oneIndexedArgNum }) {
    return `$${oneIndexedArgNum}`;
  },
  attr(ident) {
    return `"${ident}"`;
  },
  relation(ref) {
    if (typeof ref === 'string') {
      return `"${ref}"`;
    }
    return ref.schema ? `"${ref.schema}"."${ref.name}"` : `"${ref.name}"`;
  },
  informationSchema: {
    tables: (schemas: readonly string[]) => {
      const base = From({
        schema: 'information_schema',
        name: 'tables',
        attributes: {
          table_name: 'string',
          table_schema: 'string',
        },
      }).select((t) => t.pick('table_name', 'table_schema'));

      return base
        .where((t) =>
          Or(...schemas.map((schema) => Eq(schema, t.attr('table_schema'))))
        )
        .ir();
    },
    columns: (schemas: readonly string[]) => {
      const base = From({
        schema: 'information_schema',
        name: 'columns',
        attributes: {
          column_name: 'string',
          table_name: 'string',
          table_schema: 'string',
          data_type: 'string',
          is_nullable: 'string',
        },
      }).select((t) => ({
        ...t.star(),
        is_nullable: t.attr('is_nullable').eq('YES'),
      }));
      return schemas
        .reduce(
          (rel, schema) => rel.where((t) => Eq(schema, t.attr('table_schema'))),
          base
        )
        .ir();
    },
  },
  values: ({ values, attributes }) => {
    if (Object.keys(attributes).length == 0) {
      return sqlExprMacro`select null as "__unused" where false`;
    }

    if (values.length === 0) {
      return sqlExprMacro`select ${Object.keys(attributes)
        .map((name) => `null as "${name}"`)
        .join(', ')} where false`;
    }

    // Its a little weird to be aliasing to _name and back out to "name". this is due to strange behaviour in Snowflake which also uses this
    // values function. Snowflake has a quirk (see bug) where it incorrectly
    // sets the return type of a `as foo("id")` to the string `"\"id\""`
    //
    // -- This yields 'ID' (all uppercase)
    // select ID from (values (1)) as foo(id);
    //
    // -- This is an error (even freaking datagrip thinks this is correct)
    // select "id" from (values (1)) as foo("id");
    //
    // -- This is actually "correct" (this is a syntax error in datagrip, but correct in snowflake)
    // select """id""" from (values (1)) as foo("id");
    //
    // -- Workaround
    // select arg_1 as "id" from (values (1)) as foo(arg_1);
    //
    const sortedAttributes = _.sortBy(
      Object.entries(attributes),
      ([name, _ty]) => name
    );

    const inner = intersperse(
      values
        .map((row) =>
          intersperse(
            sortedAttributes.map(([attr, { ty }]) => {
              const val = row[attr] ?? null;
              return val === null
                ? ('null' as string)
                : Constant(val, { ty }).ir();
            }),
            ', '
          )
        )
        .map((val) => sqlExprMacro`(${val})`),
      [', ']
    );

    return sqlExprMacro`(select ${sortedAttributes
      .map(([name, _ty], i) => `arg_${i} as "${name}"`)
      .join(', ')} from (values ${inner}) as vals(${sortedAttributes
      .map((_, i) => `arg_${i}`)
      .join(', ')}))`;
  },
  cast: (expr, targetTy) => {
    const { ty } = Expression.fromAst(expr);
    if (ty.ty.k === 'struct' || targetTy.k === 'struct') {
      if (targetTy.k === 'struct' || targetTy.t === 'super') {
        return sqlExprMacro`(${expr})::jsonb`;
      } else if (targetTy.t === 'string') {
        return sqlExprMacro`(${expr})::text`;
      } else {
        throw new Error('unreachable');
      }
    }

    if (ty.ty.t === 'timestamp' && targetTy.t === 'string') {
      // This only works on timestamp_tz
      return sqlExprMacro`to_char(${expr} at time zone 'UTC', 'YYYY-MM-DD"T"HH24:MI:SS".000Z"')`;
    }

    if (targetTy.t === 'super') {
      return sqlExprMacro`to_jsonb(${expr})`;
    }

    if (ty.ty.t === 'super' && targetTy.t === 'string') {
      // Postgres will wrap top level strings in an additional level of quotes
      // if you cast directly to a string, this seems like a reasonable work
      // around
      return sqlExprMacro`(jsonb_build_object('value', ${expr}))->>'value'`;
    }

    return sqlExprMacro`cast(${expr} as ${typeMapping(targetTy)})`;
  },
  makeArray: (arr) => {
    const { ty } = Expression.fromAst(arr);
    Assert.assert(ty.ty.k === 'array');
    return sqlExprMacro`jsonb_build_array(${intersperse<AST.ExprIR | string>(
      arr.elements,
      ', '
    )})`;
  },
  makeRecord(fields) {
    return this.makeStruct(fields);
  },
  makeStruct(fields) {
    return sqlExprMacro`jsonb_build_object(${intersperse(
      Object.entries(fields).flatMap(([name, expr]) => [`'${name}'`, expr]),
      ', '
    )})`;
  },
  getPropertyFromStruct,
};
