import { useTenantedClient } from '@cotera/client/app/stores/org';
import { useTenantedQueryKey } from '@cotera/client/app/hooks/use-tenanted-query-key';
import { queryKeys } from '@cotera/client/app/hooks/query-cache-keys';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Assert } from '@cotera/utilities';
import { useSuspenseQueries } from '@tanstack/react-query';
import { uniqBy } from 'lodash';

export const useDetectedFeatures = (props: {
  uddId: string;
  assigned?: string;
  versionId: string;
}) => {
  const client = useTenantedClient();
  const nlpFeaturesKey = useTenantedQueryKey((orgId) =>
    queryKeys.topics.detectedFeatures({
      orgId,
      uddId: props.uddId,
      versionId: props.versionId,
    })
  );

  const [{ data: features }, { data: topics }] = useSuspenseQueries({
    queries: [
      {
        queryFn: async () =>
          Assert.assertOk(
            await client.topics.detectedFeatures({
              uddId: props.uddId,
              versionId: props.versionId,
              search: {
                assigned:
                  props.assigned === 'all' || props.assigned === undefined
                    ? undefined
                    : props.assigned === 'assigned',
              },
            })
          ),
        queryKey: nlpFeaturesKey,
      },
      {
        queryFn: async () =>
          Assert.assertOk(
            await client.topics.topics({
              versionId: props.versionId,
            })
          ),
        queryKey: useQueryKey(props),
      },
    ],
  });

  const topicFeatures = topics
    .map((topic) => topic.features)
    .flat()
    .map((x) => ({
      ...x,
      featureId: x.id,
      topics: topics
        .filter((t) => t.features.some(({ id }) => id === x.id))
        .map((t) => ({
          id: t.id,
          name: t.name,
        })),
    }));

  return {
    topics: topics.map((topic) => ({
      id: topic.id,
      name: topic.name,
      description: topic.description,
      features: topic.features.map((feature) => ({
        featureId: feature.id,
        content: feature.content,
        documentCount: feature.documentCount,
      })),
    })),
    features: uniqBy([...features.features, ...topicFeatures], 'featureId'),
  };
};

export const useVersions = (uddId: string) => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey((orgId) =>
    queryKeys.topics.versions({ orgId, uddId })
  );

  return useSuspenseQuery({
    queryFn: async () =>
      Assert.assertOk(
        await client.topics.versions({
          uddId,
        })
      ),
    queryKey,
  });
};

export const useQueryKey = (props: { uddId: string; versionId: string }) =>
  useTenantedQueryKey(['topics', 'list', props.uddId, props.versionId]);

export const useTopics = (props: { uddId: string; versionId: string }) => {
  const client = useTenantedClient();
  const queryKey = useQueryKey(props);

  return useSuspenseQuery({
    queryFn: async () =>
      Assert.assertOk(
        await client.topics.topics({
          versionId: props.versionId,
        })
      ),
    queryKey,
  });
};
