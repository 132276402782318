import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import { HorizontalPadding } from './horizontal-padding';
import React from 'react';
import { ActionsColumn } from './actions-column';
import { useDataGridStore } from '../context';

type Props = {
  index: number;
  start: number;
  onClick?: () => void;
} & ChildrenProps;

export const Row = React.forwardRef<HTMLTableRowElement, Props>(
  (props, ref) => {
    const { index, start, children } = props;
    const selectedRows = useDataGridStore((s) => s.selectedRows);

    return (
      <tr
        onClick={props.onClick}
        data-index={index}
        ref={ref}
        className={classNames(
          'flex-row',
          selectedRows.includes(index) ? 'bg-indigo-50' : ''
        )}
        style={{
          display: 'flex',
          position: 'absolute',
          transform: `translateY(${start}px)`, //this should always be a `style` as it changes on scroll
          width: '100%',
        }}
      >
        <HorizontalPadding.Cell position="start" />
        {children}
        <ActionsColumn.Body />
        <HorizontalPadding.Cell position="end" />
      </tr>
    );
  }
);
