import { Contract, POST } from '@cotera/contracts';
import { z } from 'zod';

export const EntityColumnsContract = Contract.new({
  createEraQLColumn: POST({
    params: z.object({
      entityId: z.string(),
      columnName: z.string(),
      eraql: z.string(),
    }),
    errors: 
      z.object({
        errorType: z.literal('has-dependents'),
        dependents: z.string().array(),
      }),
    output: z.object({}),
  }),
  deleteEntityColumn: POST({
    params: z.object({
      entityId: z.string().uuid(),
      columnName: z.string(),
    }),
    errors: z.never(),
    output: z.object({}),
  }),
});
