import {
  ExprVar,
  If,
  M,
  Markup,
  Relation,
  RowNumberOver,
  Sum,
} from '@cotera/era';

export const DetailTool = (
  slice: Relation,
  measure: ExprVar,
  barChartMeasures: string[],
  monthSumChartMeasures: string[],
  pieChartMeasures: string[]
): Markup => {
  return M.muCase([
    {
      when: measure.oneOf(pieChartMeasures),
      then: slice
        .countBy((t) => ({
          category: M.exprCase(
            pieChartMeasures.map((m) => ({
              when: measure.eq(m),
              then: t.attr(m).cast('string'),
            })),
            { else: 'Other' }
          ),
        }))
        .chart.PieChart((t) => ({
          value: t.attr('COUNT'),
          category: t.attr('category'),
        })),
    },
    {
      when: measure.oneOf(barChartMeasures),
      then: slice
        .countBy((t) => ({
          category: M.exprCase(
            barChartMeasures.map((m) => ({
              when: measure.eq(m),
              then: t.attr(m).cast('string'),
            })),
            { else: 'Other' }
          ),
        }))
        .select((t) => ({
          ...t.star(),
          RANK: RowNumberOver({ orderBy: t.attr('COUNT').desc() }),
        }))
        .select((t) => ({
          ...t.star(),
          category: If(t.attr('RANK').lt(10), {
            then: t.attr('category'),
            else: 'All other categories',
          }),
        }))
        .groupBy((t) => t.pick('category'))
        .select((t) => ({
          ...t.group(),
          COUNT: Sum(t.attr('COUNT')),
        }))
        .chart.BarChart((t) => ({
          y: t.attr('COUNT'),
          x: t.attr('category'),
          category: t.attr('category'),
        })),
    },
    {
      when: measure.oneOf(monthSumChartMeasures),
      then: slice
        .groupBy((t) => ({
          DETAIL_MONTH: t.attr('created_at').dateTrunc('month'),
        }))
        .select((t) => ({
          ...t.group(),
          TOTAL: Sum(
            M.exprCase(
              monthSumChartMeasures.map((m) => ({
                when: measure.eq(m),
                then: t.attr(m),
              })),
              { else: 0 }
            )
          ),
        }))
        .chart.BarChart(
          (t) => ({
            x: t.attr('DETAIL_MONTH'),
            y: t.attr('TOTAL'),
            category: measure,
          }),
          {
            axis: {
              x: {
                scale: 'month',
              },
            },
          }
        ),
    },
  ]);
};
