import { Registry } from '../types';
import { Ty } from '@cotera/era';
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '../../headless';
import { Button, Icon } from '../../ui';
import { useDataGridStore } from '../context';

export const ColumnMenu = ({
  column,
}: {
  registry: Registry;
  column: string;
  ty?: Ty.ExtendedAttributeType;
}) => {
  const hideColumn = useDataGridStore((s) => s.actions.hideColumn);
  const addSort = useDataGridStore((s) => s.actions.addSort);
  const removeSort = useDataGridStore((s) => s.actions.removeSort);
  const sortColumns = useDataGridStore((s) => s.sortColumns);

  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          <Button
            small
            icon="bars-3-bottom-right"
            inline
            iconOnly
            overrides={{ height: 'h-fit', py: '' }}
          />
        </DropdownTrigger>
        <DropdownContent>
          <DropdownItem
            icon={<Icon icon="arrow-up" />}
            active={sortColumns.some(
              (s) => s.column === column && s.direction === 'asc'
            )}
            onClick={() => {
              addSort(column, 'asc');
            }}
          >
            Sort Asc
          </DropdownItem>
          <DropdownItem
            icon={<Icon icon="arrow-down" />}
            active={sortColumns.some(
              (s) => s.column === column && s.direction === 'desc'
            )}
            onClick={() => {
              addSort(column, 'desc');
            }}
          >
            Sort Desc
          </DropdownItem>
          <DropdownItem
            icon={<Icon icon="arrows-up-down" />}
            active={!sortColumns.some((s) => s.column === column)}
            onClick={() => {
              removeSort(column);
            }}
          >
            No Sort
          </DropdownItem>
          <DropdownItem
            icon={<Icon icon="eye-slash" />}
            active={false}
            onClick={() => {
              hideColumn(column);
            }}
          >
            Hide
          </DropdownItem>
        </DropdownContent>
      </Dropdown>
    </>
  );
};
