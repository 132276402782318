import { Ty } from '../../ty';
import { Expression } from '../expression';
import { MacroArgs, MacroBody, mkMacroVars } from './macro-base';
import hash from 'object-hash';
import _ from 'lodash';
import { AST } from '../../ast';
import { Relation } from '../relation';
import { Assert } from '../../utils';

export type ExprMacro<Args extends MacroArgs> = {
  call: MacroBody<Args, Expression>;
  body: Expression;
  scope: string;
};

export const mkExprMacro = <Args extends MacroArgs>(
  args: Args,
  body: (args: Args) => Expression | Ty.Scalar,
  opts?: { readonly displayName?: string }
): ExprMacro<Args> => {
  const scope = `${opts?.displayName ? `${opts.displayName}-` : ''}${hash({
    args,
    body: body.toString(),
    opts: opts ?? {},
  })}`;

  const mBody = Expression.wrap(body(mkMacroVars(scope, args)));

  const callback: MacroBody<Args, Expression> = (params) => {
    const exprs: Record<string, AST.ExprFR> = _.chain(params)
      .entries()
      .map(([name, _def]) => {
        const val = params[name];
        Assert.assert(val !== undefined && !(val instanceof Relation));
        return [name, Expression.wrap(val).ast];
      })
      .fromPairs()
      .value();

    return Expression.fromAst({
      t: 'macro-apply-vars-to-expr',
      scope,
      vars: { exprs },
      sources: { from: mBody.ast },
      displayName: opts?.displayName ?? null,
    });
  };

  return { call: callback, body: mBody, scope };
};

export const exprIf = (
  condition: boolean | Expression,
  branches: {
    readonly then: Ty.Scalar | Expression;
    readonly else: Ty.Scalar | Expression;
  }
): Expression =>
  exprCase([{ when: condition, then: branches.then }], {
    else: branches.else,
  });

export const exprCase = (
  cases: readonly {
    readonly when: boolean | Expression;
    readonly then: Ty.Scalar | Expression;
  }[],
  opts: { else: Ty.Scalar | Expression }
): Expression =>
  Expression.fromAst({
    t: 'macro-expr-case',
    cases: cases.map(({ when, then }) => ({
      when: Expression.wrap(when).ast,
      then: Expression.wrap(then).ast,
    })),
    else: Expression.wrap(opts.else).ast,
  });
