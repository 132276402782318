import { DetailTool } from '@cotera/customers/apps/detail-tool';
import {
  Relation,
  AST,
  Page,
  UI,
  f,
  Sum,
  SumOver,
  Ty,
  Constant,
  M,
  And,
  FirstValueOver,
  UnionAll,
  CountDistinct,
  Count,
} from '@cotera/era';

const barChartMeasures: string[] = [
  'RATING',
  'CAREER_OPPORTUNITIES_RATING',
  'COMPENSATION_AND_BENEFITS_RATING',
  'CULTURE_AND_VALUES_RATING',
  'DIVERSITY_AND_INCLUSION_RATING',
];
const monthSumChartMeasures: string[] = [];
const pieChartMeasures: string[] = [
  'JOB_TITLE',
  'EMPLOYMENT_STATUS',
  'BUSINESS_OUTLOOK_RATING',
  'CEO_RATING',
  'RECOMMEND_TO_FRIEND_RATING',
  'SENIOR_MANAGEMENT_RATING',
  'WORK_LIFE_BALANCE_RATING',
  'LOCATION',
];

export const Overview = ({
  CompanyReviews,
  CompanyCompetitors,
  GlassDoorReviews,
}: {
  CompanyReviews: Relation;
  CompanyCompetitors: Relation;
  GlassDoorReviews: Relation;
}): AST._Page => {
  return Page(['companyName'] as const, ({ companyName }) => {
    return [
      UI.State(
        {
          input: CompanyReviews.where((t) =>
            t.attr('COMPANY_NAME').eq(companyName)
          ),
          tab: Constant('Percent', { ty: Ty.e(['Percent', 'Count']) }),
          measure_dropdown: Constant('RATING', {
            ty: Ty.e([
              ...barChartMeasures,
              ...monthSumChartMeasures,
              ...pieChartMeasures,
            ]),
          }),
        },
        ({ input, tab, measure_dropdown }) => {
          const ReviewCountByTag = input
            .countBy((t) => t.pick('TAG_KEY'))
            .select((t) => ({
              ...t.pick('TAG_KEY'),
              COUNT: t
                .attr('COUNT')
                .div(SumOver(t.attr('COUNT')))
                .mul(100)
                .round(0),
            }));

          const ReviewCountBySource = input
            .countBy((t) => t.pick('SOURCE'))
            .select((t) => ({
              ...t.pick('SOURCE'),
              COUNT: t
                .attr('COUNT')
                .div(SumOver(t.attr('COUNT')))
                .mul(100)
                .round(0),
            }));

          const TagSentiment = input
            .countBy((t) => t.pick('TAG_KEY', 'TAG_VALUE'))
            .select((t) => ({
              ...t.pick('TAG_KEY', 'TAG_VALUE'),
              COUNT: t
                .attr('COUNT')
                .div(SumOver(t.attr('COUNT')))
                .mul(100)
                .round(0),
              PERCENT: t
                .attr('COUNT')
                .div(
                  SumOver(t.attr('COUNT'), { partitionBy: t.attr('TAG_KEY') })
                )
                .mul(100),
            }));

          const PositiveReviews = TagSentiment.where((t) =>
            t.attr('TAG_VALUE').eq('1')
          );

          const MostPositiveReview = input
            .where((t) =>
              And(
                t.attr('TAG_VALUE').eq('1'),
                t.attr('SOURCE').isDistinctFrom('forum')
              )
            )
            .groupBy((t) => t.pick('REVIEW_ID', 'COMMENT', 'TIMESTAMP'))
            .select((t) => ({
              ...t.group(),
              COUNT: Sum(t.attr('TAG_VALUE').cast('int')),
            }))
            .select(
              (t) => ({
                COMMENT: FirstValueOver(t.attr('COMMENT'), {
                  orderBy: [t.attr('COUNT').desc(), t.attr('TIMESTAMP').desc()],
                }),
              }),
              { distinct: true }
            );

          const NegativeReviews = TagSentiment.where((t) =>
            t.attr('TAG_VALUE').eq('-1')
          );

          const MostNegativeReview = input
            .where((t) =>
              And(
                t.attr('TAG_VALUE').eq('-1'),
                t.attr('SOURCE').isDistinctFrom('forum')
              )
            )
            .groupBy((t) => t.pick('REVIEW_ID', 'COMMENT', 'TIMESTAMP'))
            .select((t) => ({
              ...t.group(),
              COUNT: Sum(t.attr('TAG_VALUE').cast('int')),
            }))
            .select(
              (t) => ({
                COMMENT: FirstValueOver(t.attr('COMMENT'), {
                  orderBy: [t.attr('COUNT').asc(), t.attr('TIMESTAMP').desc()],
                }),
              }),
              { distinct: true }
            );

          const Competitors = UnionAll([
            CompanyCompetitors.where((t) =>
              t.attr('DOMAIN').replace('.com', '').eq(companyName)
            ).leftJoin(CompanyReviews, (competitor, revs) => ({
              on: competitor.attr('COMPETITOR').eq(revs.attr('DOMAIN')),
              select: {
                ...revs.star(),
              },
            })),
            input,
          ]);

          const CompetitorRelevantTags = Competitors.innerJoin(
            input.select((t) => t.pick('TAG_KEY'), { distinct: true }),
            (comp, input) => ({
              on: comp.attr('TAG_KEY').eq(input.attr('TAG_KEY')),
              select: {
                ...comp.star(),
              },
            })
          );

          const CompetitorReviewCount = CompetitorRelevantTags.groupBy((t) =>
            t.pick('DOMAIN')
          ).select((t) => ({
            ...t.group(),
            TOTAL_REVIEWS: CountDistinct(t.attr('REVIEW_ID')),
          }));

          const CompetitorSummary = CompetitorRelevantTags.countBy((t) =>
            t.pick('DOMAIN', 'TAG_KEY', 'TAG_VALUE')
          )
            .leftJoin(CompetitorReviewCount, (t, count) => ({
              on: t.attr('DOMAIN').eq(count.attr('DOMAIN')),
              select: {
                ...t.star(),
                ...count.pick('TOTAL_REVIEWS'),
              },
            }))
            .select((t) => ({
              ...t.star(),
              PERCENT: t
                .attr('COUNT')
                .safeDiv(t.attr('TOTAL_REVIEWS'))
                .mul(100),
            }));

          const CompanyGlassdoor = GlassDoorReviews.select((t) => ({
            ...t.star(),
            COMPANY_NAME: t.attr('DOMAIN').replace('.com', ''),
          })).where((t) => t.attr('COMPANY_NAME').eq(companyName));

          const MostHelpfulPositiveReview = CompanyGlassdoor.select((t) => ({
            ...t.star(),
            TOP_REVIEW: FirstValueOver(t.attr('REVIEW_ID'), {
              orderBy: [
                t.attr('RATING').desc(),
                t.attr('HELPFUL_COUNT').desc(),
                t.attr('REVIEW_DATETIME').desc(),
              ],
            }),
          })).where((t) => t.attr('TOP_REVIEW').eq(t.attr('REVIEW_ID')));

          const MostHelpfulCriticalReview = CompanyGlassdoor.select((t) => ({
            ...t.star(),
            TOP_REVIEW: FirstValueOver(t.attr('REVIEW_ID'), {
              orderBy: [
                t.attr('RATING').asc(),
                t.attr('HELPFUL_COUNT').desc(),
                t.attr('REVIEW_DATETIME').desc(),
              ],
            }),
          })).where((t) => t.attr('TOP_REVIEW').eq(t.attr('REVIEW_ID')));

          return [
            UI.Header({
              title: f`${companyName} Reviews`,
              caption:
                'This report breaks down specific item buying trends and customer behavior.',
            }),
            input.SliceBuilder(),
            input
              .summary((_t) => ({ COUNT: Count() }))
              .chart.ForEachRow((row) =>
                M.muIf(row.getField('COUNT').gt(0), {
                  then: [
                    UI.Block([
                      UI.Row([
                        UI.Half([
                          ReviewCountBySource.groupBy((t) => t.pick('SOURCE'))
                            .select((t) => ({
                              ...t.group(),
                              COUNT: Sum(t.attr('COUNT')),
                            }))
                            .chart.SummaryChart(
                              (t) => ({
                                category: t.attr('SOURCE'),
                                value: t.attr('COUNT'),
                              }),
                              { title: 'Review Sources' }
                            ),
                        ]),
                        UI.Half([
                          ReviewCountByTag.groupBy((t) => t.pick('TAG_KEY'))
                            .select((t) => ({
                              ...t.group(),
                              COUNT: Sum(t.attr('COUNT')),
                            }))
                            .chart.SummaryChart(
                              (t) => ({
                                category: t.attr('TAG_KEY'),
                                value: t.attr('COUNT'),
                              }),
                              { title: 'Review Category Breakdown' }
                            ),
                        ]),
                      ]),
                    ]),
                    UI.Block(
                      [
                        UI.Row([
                          UI.Third([
                            TagSentiment.groupBy((t) => t.pick('TAG_VALUE'))
                              .select((t) => ({
                                ...t.group(),
                                COUNT: Sum(t.attr('COUNT')),
                              }))
                              .chart.PieChart((t) => ({
                                category: t.attr('TAG_VALUE').match({
                                  '-1': 'Negative',
                                  '0': 'Neutral',
                                  '1': 'Positive',
                                }),
                                value: t.attr('COUNT'),
                                style: t.attr('TAG_VALUE').match({
                                  '-1': 'negative',
                                  '0': 'neutral',
                                  '1': 'positive',
                                }),
                              })),
                          ]),
                          UI.TwoThirds([
                            UI.TabsV2(tab, [
                              M.muIf(tab.eq('Count'), {
                                then: TagSentiment.chart.BarChart(
                                  (t) => ({
                                    x: t.attr('TAG_KEY'),
                                    category: t.attr('TAG_VALUE').match({
                                      '-1': 'Negative',
                                      '0': 'Neutral',
                                      '1': 'Positive',
                                    }),
                                    y: t.attr('COUNT'),
                                    style: t.attr('TAG_VALUE').match({
                                      '-1': 'negative',
                                      '0': 'neutral',
                                      '1': 'positive',
                                    }),
                                  }),
                                  {
                                    axis: {
                                      y: {
                                        label: 'Count',
                                      },
                                    },
                                  }
                                ),
                                else: TagSentiment.chart.BarChart(
                                  (t) => ({
                                    x: t.attr('TAG_KEY'),
                                    category: t.attr('TAG_VALUE').match({
                                      '-1': 'Negative',
                                      '0': 'Neutral',
                                      '1': 'Positive',
                                    }),
                                    y: t.attr('PERCENT'),
                                    style: t.attr('TAG_VALUE').match({
                                      '-1': 'negative',
                                      '0': 'neutral',
                                      '1': 'positive',
                                    }),
                                  }),
                                  {
                                    axis: {
                                      y: {
                                        label: 'Percent (%)',
                                      },
                                    },
                                  }
                                ),
                              }),
                            ]),
                          ]),
                        ]),
                      ],
                      { title: 'Sentiment Overview' }
                    ),
                    UI.Row([
                      UI.TwoThirds([
                        PositiveReviews.chart.BarChart(
                          (t) => ({
                            x: t.attr('TAG_KEY'),
                            y: t.attr('COUNT'),
                            category: t.attr('TAG_KEY'),
                            style: 'positive',
                          }),
                          {
                            title: 'Positive Review Category Breakdown',
                            axis: {
                              y: {
                                label: 'Percent (%)',
                              },
                            },
                          }
                        ),
                      ]),
                      UI.Third([
                        UI.Block(
                          [
                            MostPositiveReview.chart.ForEachRow(
                              (row) => f`${row.getField('COMMENT')}`
                            ),
                          ],
                          { title: 'Most Positive Review' }
                        ),
                      ]),
                    ]),
                    UI.Row([
                      UI.TwoThirds([
                        NegativeReviews.chart.BarChart(
                          (t) => ({
                            x: t.attr('TAG_KEY'),
                            y: t.attr('COUNT'),
                            category: t.attr('TAG_KEY'),
                            style: 'negative',
                          }),
                          {
                            title: 'Negative Review Category Breakdown',
                            axis: {
                              y: {
                                label: 'Percent (%)',
                              },
                            },
                          }
                        ),
                      ]),
                      UI.Third([
                        UI.Block(
                          [
                            MostNegativeReview.chart.ForEachRow(
                              (row) => f`${row.getField('COMMENT')}`
                            ),
                          ],
                          { title: 'Most Negative Review' }
                        ),
                      ]),
                    ]),
                  ],
                })
              ),
            Competitors.summary((t) => ({
              COUNT: CountDistinct(t.attr('DOMAIN')),
            })).chart.ForEachRow((row) =>
              M.muIf(row.getField('COUNT').gt(1), {
                then: [
                  UI.Block([
                    CompetitorSummary.where((t) =>
                      t.attr('TAG_VALUE').eq('1')
                    ).chart.BarChart(
                      (t) => ({
                        x: t.attr('TAG_KEY'),
                        y: t.attr('PERCENT'),
                        category: t.attr('DOMAIN'),
                      }),
                      {
                        title: 'Competitor Positive Review Breakdown',
                        type: 'grouped',
                        axis: {
                          y: {
                            label: 'Percent (%)',
                          },
                        },
                      }
                    ),
                    CompetitorSummary.where((t) =>
                      t.attr('TAG_VALUE').eq('-1')
                    ).chart.BarChart(
                      (t) => ({
                        x: t.attr('TAG_KEY'),
                        y: t.attr('PERCENT'),
                        category: t.attr('DOMAIN'),
                      }),
                      {
                        title: 'Competitor Negative Review Breakdown',
                        type: 'grouped',
                        axis: {
                          y: {
                            label: 'Percent (%)',
                          },
                        },
                      }
                    ),
                  ]),
                ],
              })
            ),
            CompanyGlassdoor.summary((_t) => ({
              COUNT: Count(),
            })).chart.ForEachRow((row) =>
              M.muIf(row.getField('COUNT').gt(0), {
                then: [
                  UI.Block(
                    [
                      [measure_dropdown.TabSelector()],
                      UI.Divider(),
                      DetailTool(
                        CompanyGlassdoor.select((t) => ({
                          ...t.star(),
                          created_at: t.attr('REVIEW_DATETIME'),
                        })),
                        measure_dropdown,
                        barChartMeasures,
                        monthSumChartMeasures,
                        pieChartMeasures
                      ),
                    ],
                    { title: 'Glassdoor Review Details' }
                  ),
                  UI.Row([
                    UI.Half([
                      UI.Block(
                        [
                          '#Summary:\n',
                          MostHelpfulPositiveReview.chart.ForEachRow(
                            (row) => f`${row.getField('SUMMARY')}\n\n`
                          ),
                          '#Pros:\n',
                          MostHelpfulPositiveReview.chart.ForEachRow(
                            (row) => f`${row.getField('PROS')}\n\n`
                          ),
                          '#Cons:\n',
                          MostHelpfulPositiveReview.chart.ForEachRow(
                            (row) => f`${row.getField('CONS')}`
                          ),
                        ],
                        {
                          title: 'Most Helpful Positive Review',
                        }
                      ),
                    ]),
                    UI.Half([
                      UI.Block(
                        [
                          '#Summary:\n',
                          MostHelpfulCriticalReview.chart.ForEachRow(
                            (row) => f`${row.getField('SUMMARY')}\n\n`
                          ),
                          '#Pros:\n',
                          MostHelpfulCriticalReview.chart.ForEachRow(
                            (row) => f`${row.getField('PROS')}\n\n`
                          ),
                          '#Cons:\n',
                          MostHelpfulCriticalReview.chart.ForEachRow(
                            (row) => f`${row.getField('CONS')}`
                          ),
                        ],
                        { title: 'Most Helpful Critical Review' }
                      ),
                    ]),
                  ]),
                ],
              })
            ),
          ];
        }
      ),
    ];
  });
};
