import React, { Suspense } from 'react';
import { Column, Table as ITable } from '@tanstack/react-table';
import {
  DropdownItem,
  useDropdown,
} from '@cotera/client/app/components/headless';
import {
  Button,
  Divider,
  Icon,
  Loading,
  Modal,
  Title,
  toast,
} from '@cotera/client/app/components/ui';
import { useTenantedClient } from '@cotera/client/app/stores/org';
import { useMutation } from '@tanstack/react-query';
import { ComboBox, Inputs } from '@cotera/client/app/components/forms';
import { z } from 'zod';
import { usePrompts } from '@cotera/client/app/hooks/prompts';
import { useEntity } from '@cotera/client/app/hooks/entities';
import { useTopicVersions } from '@cotera/client/app/hooks/topics';
import { useSearchParam } from 'react-use';
import { ErrorBoundary } from '@cotera/client/app/error-boundary';
import { Values } from '@cotera/era';
import { err, ok } from 'neverthrow';
import { groupBy, snakeCase, uniq } from 'lodash';
import { useDataGridStore } from '../context';
import { DuckDBQueryResult } from '@cotera/client/app/etc/duckdb';

const useTopicMatching = (params: {
  summaryUddId: string;
  topicUddId: string;
}) => {
  const client = useTenantedClient();

  return useMutation({
    mutationFn: async (props: {
      versionId: string;
      topicExtractionPromptId: string;
      summaryPromptId: string;
      messages: {
        id: string;
        coteraStableId: string;
        content: string;
      }[];
    }) => {
      return await client.topics.match({
        messages: props.messages,
        versionId: props.versionId,
        topicExtractionPromptId: props.topicExtractionPromptId,
        summaryPromptId: props.summaryPromptId,
        uddId: params.topicUddId,
        summaryUddId: params.summaryUddId,
      });
    },
  });
};

const configSchema = z.object({
  params: z.object({
    topicVersionId: z.string().nullable(),
    topicExtractionPromptId: z.string().nullable(),
    summaryPromptId: z.string().nullable(),
    topicUddId: z.string(),
    summaryUddId: z.string(),
    entityName: z.string(),
  }),
});

type Config = z.infer<typeof configSchema>;

function getFirstValueOrDefault(
  column: string,
  data: DuckDBQueryResult
): z.infer<typeof configSchema> {
  const defaultValue = {
    params: {
      topicVersionId: null,
      topicExtractionPromptId: null,
      summaryPromptId: null,
      entityName: '',
      topicUddId: '',
      summaryUddId: '',
    },
  };

  if (!data?.length) {
    return defaultValue;
  }

  const parsed = configSchema.safeParse(data.column(column).valueAt(0));
  return parsed.success ? parsed.data : defaultValue;
}

export const RunTopicMatchingButton: React.FC<{
  table: ITable<any>;
  column: Column<any, any>;
  onClick?: () => void;
}> = ({ onClick }) => {
  const toggleDropdown = useDropdown((s) => s.actions.toggle);

  return (
    <DropdownItem
      icon={<Icon icon="play" />}
      onClick={() => {
        onClick?.();
        toggleDropdown();
      }}
    >
      Run topic matching
    </DropdownItem>
  );
};

export const RunTopicMatchingView: React.FC<{
  column: string;
  onClose: () => void;
  show: boolean;
}> = ({ column, onClose, show }) => {
  const data = useDataGridStore((s) => s.data);
  const { params: setupValues } = getFirstValueOrDefault(column, data!);
  const [running, setRunning] = React.useState(false);
  const transform = useDataGridStore((s) => s.actions.transform);

  return (
    <Modal
      open={show}
      onOpenChange={() => {
        onClose();
      }}
      center={false}
      priority="medium"
    >
      <div className="min-w-[300px] w-[60%]">
        <ErrorBoundary>
          <Suspense fallback={<Loading.Form />}>
            <Form
              values={setupValues}
              running={running}
              onResults={(results, version) => {
                if (results.length === 0) {
                  setRunning(false);
                  toast.warning('No results found');
                  return;
                }
                const columns = uniq(results.map((r) => r.topicName));
                const byId = groupBy(results, (r) => r.coteraStableId);
                const attrName = `${version!.display}`;
                const resultsWithColumns = Object.entries(byId).map(
                  ([coteraStableId, rows]) => ({
                    coteraStableId,
                    ...Object.fromEntries(
                      columns.map((c) => [
                        snakeCase(`${c}_${attrName}`),
                        rows.some((r) => r.topicName === c),
                      ])
                    ),
                  })
                );
                const valuesFromResults = Values(resultsWithColumns);
                transform((rel) =>
                  rel.leftJoin(valuesFromResults, (left, values) => ({
                    on: left
                      .attr(column)
                      .getField('params')
                      .getField('coteraStableId')
                      .eq(values.attr('coteraStableId')),
                    select: {
                      ...values.except('coteraStableId'),
                      ...left.star(),
                    },
                  }))
                );
                setRunning(false);
                onClose();
              }}
              onRun={() => {
                setRunning(true);
              }}
              column={column}
            />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Modal>
  );
};

const useVirtualTopicColumns = (
  tableData: DuckDBQueryResult,
  column: string,
  udds: {
    summaryId: string;
    topicId: string;
  }
) => {
  const matcher = useTopicMatching({
    summaryUddId: udds.summaryId,
    topicUddId: udds.topicId,
  });
  const { data: topicVersions } = useTopicVersions(udds.topicId);

  const run = async (
    maxMessages: number,
    topicVersion: { value: string; display: string },
    extractionPrompt: { value: string; display: string },
    summaryPrompt: { value: string; display: string }
  ) => {
    const selectedTopicVersion = topicVersions.find(
      (t) => t.id === topicVersion.value
    );
    const rows = tableData.slice(0, maxMessages).column(column).toArray();
    const values = rows.map((x) => x.params);
    const results = await matcher.mutateAsync({
      versionId: topicVersion.value,
      topicExtractionPromptId: extractionPrompt.value,
      summaryPromptId: summaryPrompt.value,
      messages: values,
    });
    if (results.isErr()) {
      toast.error(JSON.stringify(results.error));

      return err(results.error);
    }
    return ok(
      results.value.matches.map((x) => ({
        ...x,
        topicName:
          selectedTopicVersion?.topics.find((t) => t.id === x.topicId)?.name ??
          'Unknown',
      }))
    );
  };

  return run;
};

const Form: React.FC<{
  column: string;
  values: Config['params'];
  onResults: (
    results: {
      coteraStableId: string;
      topicId: string;
      sentiment: number;
      topicName: string;
    }[],
    topicVersion?: { value: string; display: string }
  ) => void;
  onRun: () => void;
  running: boolean;
}> = ({ values, column, onResults, onRun, running }) => {
  const data = useDataGridStore((s) => s.data);
  const topicVersionId = useSearchParam('topicVersionId');
  const entity = useEntity({ entityName: values.entityName });
  const matcher = useVirtualTopicColumns(data!, column, {
    summaryId: values.summaryUddId,
    topicId: values.topicUddId,
  });
  const { data: topicVersions } = useTopicVersions(entity.uuid);
  const { data: promptsResult } = usePrompts();
  const [topicVersion, setTopicVersion] = React.useState(
    topicVersions
      .map((t) => ({ value: t.id, display: t.version }))
      .find((t) => t.value === (topicVersionId ?? values.topicVersionId))
  );
  const prompts = promptsResult.prompts.map((p) => ({
    value: p.id,
    display: p.name,
  }));
  const [extractionPrompt, setExtractionPrompt] = React.useState(
    prompts.find((p) => p.value === values.topicExtractionPromptId)
  );
  const [summaryPrompt, setSummaryPrompt] = React.useState(
    prompts.find((p) => p.value === values.summaryPromptId)
  );
  const [loading, setLoading] = React.useState(false);
  const [maxMessages, setMaxMessages] = React.useState(1000);

  return (
    <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
      <Title type="section" title="Run topic matching" />
      <Divider />
      <ComboBox.Single
        label="Topic version"
        value={topicVersion}
        onChange={setTopicVersion}
        options={({ query }) => (
          <ComboBox.StaticOptions
            options={
              topicVersions?.map((t) => ({
                value: t.id,
                display: t.version,
              })) ?? []
            }
            query={query}
          />
        )}
      />
      <ComboBox.Single
        label="Topic Extraction prompt"
        value={extractionPrompt}
        onChange={setExtractionPrompt}
        options={({ query }) => (
          <ComboBox.StaticOptions options={prompts} query={query} />
        )}
      />
      <ComboBox.Single
        label="Summary prompt"
        value={summaryPrompt}
        onChange={setSummaryPrompt}
        options={({ query }) => (
          <ComboBox.StaticOptions options={prompts} query={query} />
        )}
      />
      <Inputs.Number
        label="Max messages"
        value={maxMessages}
        onChange={setMaxMessages}
      />
      <div className="flex w-full justify-end">
        <Button
          className={loading || running ? 'animate-pulse' : ''}
          theme="primary"
          disabled={loading || running}
          text={loading || running ? 'Running...' : 'Run'}
          icon="play"
          onClick={async () => {
            if (topicVersion && extractionPrompt && summaryPrompt) {
              onRun();
              setLoading(true);
              const result = await matcher(
                maxMessages,
                topicVersion,
                extractionPrompt,
                summaryPrompt
              );
              setLoading(false);
              if (result.isErr()) {
                toast.error(JSON.stringify(result.error));
                onResults([], topicVersion);
                return;
              }
              onResults(result.value, topicVersion);
            } else {
              toast.error('Please select all options');
            }
          }}
        />
      </div>
    </form>
  );
};
