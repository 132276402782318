export const DATE_FUNCTIONS = [
  'date_diff',
  'date_add',
  'date_trunc',
  'date_part',
] as const;

export const STRING_FUNCTIONS = [
  'lower',
  'upper',
  'like',
  '||',
  'length',
  'replace',
  'split_part',
] as const;

export const MATH_FUNCTIONS = ['+', '-', '*', '/', '^'] as const;
export const CONNECTIVE_FUNCTIONS = ['and', 'or'] as const;
export const COMPARISON_FUNCTIONS = ['=', '!=', '>=', '>', '<=', '<'] as const;

export type MathFunction = (typeof MATH_FUNCTIONS)[number];
export type Comparison = (typeof COMPARISON_FUNCTIONS)[number];
export type Connective = (typeof CONNECTIVE_FUNCTIONS)[number];

export const UTILITY_FUNCTIONS = [
  'nan',
  'is_nan',
  'not',
  'is_null',
  'gen_random_uuid',
  'now',
  'random',
  'nullif',
  '??',
  'impure',
] as const;

export const AGGREGATE_FUNCTIONS = [
  'sum',
  'count',
  'count_distinct',
  'avg',
  'min',
  'max',
  'corr',
  'stddev_samp',
  'stddev_pop',
  'string_agg',
  'array_agg',
] as const;

export const RECORD_FUNCTIONS = ['get_from_record'] as const;

export const TYPE_LEVEL_FUNCTIONS = [
  'implements',
  'type_of',
  'null_of',
  'tag',
] as const;

export const FUNCTION_IDENTIFIERS = [
  'abs',
  'ln',
  'log_2',
  'log_10',
  'percentile_cont',
  'percentile_disc',
  'floor',
  'ceil',
  'cosine_distance',
  'round',
  'is_numeric_string',
  ...STRING_FUNCTIONS,
  ...MATH_FUNCTIONS,
  ...CONNECTIVE_FUNCTIONS,
  ...COMPARISON_FUNCTIONS,
  ...UTILITY_FUNCTIONS,
  ...AGGREGATE_FUNCTIONS,
  ...DATE_FUNCTIONS,
  ...RECORD_FUNCTIONS,
  ...TYPE_LEVEL_FUNCTIONS,
] as const;

export const DATE_PART_UNITS = [
  'minute',
  'hour',
  'day',
  'dow',
  'week',
  'month',
  'quarter',
  'year',
] as const;

export const DATE_DIFF_UNITS = ['days', 'years', 'seconds'] as const;
export const TIMESTAMP_DIFF_UNITS = ['seconds'] as const;

export const DATE_ADD_UNITS = ['days'] as const;

export const DATE_TRUNC_UNITS = [
  'minute',
  'hour',
  'day',
  'week',
  'month',
  'quarter',
  'year',
] as const;

export type DateTruncUnit = (typeof DATE_TRUNC_UNITS)[number];

export type FunctionIdentifier = (typeof FUNCTION_IDENTIFIERS)[number];

const funcIdSet = new Set(FUNCTION_IDENTIFIERS);
export const isFunctionIdentifier = (
  name: string
): name is FunctionIdentifier => funcIdSet.has(name as any);
