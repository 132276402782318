import { TC, TyStackTrace } from '../type-checker';
import _ from 'lodash';
import { err, ok } from 'neverthrow';
import { Expression } from '../builder';
import { Ty } from '../ty';
import { ParseError, ParseRes } from './eraql-ast/ast';
import { parseExpr } from './parse';
import {
  AggregateFunctionInNonAggregatedContext,
  TypeDoesNotMatchExpectation,
} from '../type-checker/type-check-error';

export const compileExprQl = (x: {
  sql: string;
  attributes: Record<string, Expression>;
  allowAggregate: boolean;
  typeImplsOneOf?: readonly Ty.Shorthand[];
}): ParseRes<Expression> => {
  const { attributes, allowAggregate, sql, typeImplsOneOf } = x;
  const parsed = parseExpr(sql, { attributes }).andThen(
    (ir): ParseRes<Expression> => {
      const expr = Expression.tryfromAst(ir);
      return expr.isOk()
        ? ok(expr.value)
        : err([
            { t: 'type-check-error', trace: expr.error },
            { range: [0, sql.length] },
          ]);
    }
  );

  if (parsed.isOk()) {
    const subject = parsed.value.ty;

    const isCorrectTy =
      typeImplsOneOf === undefined ||
      typeImplsOneOf.some((req) => TC.implementsTy({ subject, req }));

    const isIncorrectlyAggregated =
      !allowAggregate && parsed.value.isAggregated;

    if (!isCorrectTy) {
      const e: ParseError = {
        t: 'type-check-error',
        trace: TyStackTrace.fromErr(
          {},
          new TypeDoesNotMatchExpectation({
            found: parsed.value.ty,
            expected: typeImplsOneOf,
          })
        ),
      };
      return err([e, { range: [0, sql.length - 1] }]);
    } else if (isIncorrectlyAggregated) {
      const e: ParseError = {
        t: 'type-check-error',
        trace: TyStackTrace.fromErr(
          {},
          new AggregateFunctionInNonAggregatedContext()
        ),
      };

      return err([e, { range: [0, sql.length - 1] }]);
    }
  }

  return parsed;
};
