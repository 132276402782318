import {
  makeStoreContextHook,
  makeStoreProvider,
  StateSetter,
} from '@cotera/client/app/etc';
import { createContext } from 'react';
import { ChildrenProps } from '@cotera/client/app/components/utils';
import { Ty } from '@cotera/era';

type Filter = {
  t: Ty.AttributeType;
  values: string[];
};

type State = {
  filters: Record<string, Filter>;
};

type Actions = (set: StateSetter<State>) => {
  update: (key: string, value: Filter) => void;
};

const Context = createContext<State>(undefined as any);

const InternalFilterProvider = makeStoreProvider<State, ReturnType<Actions>>(
  Context
);

export const useFilterContext = makeStoreContextHook<
  State,
  ReturnType<Actions>
>(Context);

const actions: Actions = (set) => ({
  update: (key, value) => {
    set((state) => {
      const newFilters = { ...state.filters };
      newFilters[key] = value;

      if (value.values.length === 0) {
        delete newFilters[key];
      }

      return {
        filters: newFilters,
      };
    });
  },
});

export const FilterProvider: React.FC<
  ChildrenProps & {
    filters?: Record<string, Filter>;
  }
> = ({ children, filters = {} }) => {
  return (
    <InternalFilterProvider
      state={{
        filters,
      }}
      actions={actions}
    >
      {children}
    </InternalFilterProvider>
  );
};
