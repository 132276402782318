import { SQL, Ty, EraQL } from '@cotera/era';
import _ from 'lodash';
import { useState } from 'react';

type Candiate = {
  column_name: string;
  data_type: string;
  is_nullable: boolean;
};

type Draft = {
  isPrimary: boolean;
  isEnabled: boolean;
  eraTy: string;
};

export type Assumption = Candiate &
  Draft & {
    setPrimary: (val: boolean) => void;
    setEraTy: (val: string) => void;
    setEnabled: (val: boolean) => void;
    tyParse: EraQL.ParseRes<Ty.ExtendedAttributeType>;
  };

export function useAssumptionBuilder(params: {
  entityIdType: Ty.IdType;
  dialect: SQL.SqlDialect;
  candidates: Candiate[];
}): { assumptions: Assumption[]; valid: boolean } {
  const { dialect, candidates, entityIdType } = params;

  const [primaryCol, setPrimaryCol] = useState<null | string>(
    candidates.find((x) => x.column_name.toLowerCase() === 'id')?.column_name ??
      null
  );

  const [drafts, setDrafts] = useState<(Candiate & Omit<Draft, 'isPrimary'>)[]>(
    candidates.map((col) => {
      const isPrimary = col.column_name === primaryCol;
      const eraTy = inferEraType(col, dialect) ?? '';
      return { ...col, isEnabled: isPrimary, eraTy };
    })
  );

  const assumptions = drafts
    .map((draft, idx): Omit<Assumption, 'tyParse'> => {
      const isPrimary = primaryCol === draft.column_name;

      const set = (partial: Partial<Draft>) => {
        setDrafts((existing) => {
          const updated = existing.map((old, i) =>
            i === idx ? { ...old, ...partial } : old
          );
          return updated;
        });
      };

      const setEnabled = (val: boolean) => {
        if (val === false && isPrimary) {
          setPrimaryCol(null);
        }

        set({ isEnabled: val });
      };

      const setEraTy = (val: string) => set({ eraTy: val });

      const setPrimary = (val: boolean) => {
        if (val) {
          setPrimaryCol(draft.column_name);
        } else if (isPrimary) {
          setPrimaryCol(null);
        }
      };

      return { ...draft, isPrimary, setPrimary, setEnabled, setEraTy };
    })
    .map((draft): Assumption => {
      const nullability = draft.is_nullable ? Ty.ty : Ty.nn;

      const eraTy = draft.isPrimary
        ? Ty.displayTy(nullability(entityIdType))
        : draft.eraTy;

      return { ...draft, eraTy, tyParse: EraQL.parseTy(eraTy) };
    });

  const valid =
    primaryCol !== null && assumptions.every((draft) => draft.tyParse.isOk());

  return { assumptions, valid };
}

const inferEraType = (
  candidate: Candiate,
  dialect: SQL.SqlDialect
): string | null => {
  const nullability = candidate.is_nullable ? Ty.ty : Ty.nn;

  const warehouseTypeIsEra = EraQL.parseTy(candidate.data_type)
    .map(nullability)
    .map(Ty.displayTy);

  if (warehouseTypeIsEra.isOk()) {
    return warehouseTypeIsEra.value;
  }

  const inferred = dialect.tryWarehouseTypeToEraType(candidate.data_type);

  if (inferred !== null) {
    const eTy = nullability(inferred);
    return Ty.displayTy(eTy);
  }

  return null;
};
