import { AST } from '../../ast';
import { displayTy } from '../../ty/ty';
import { Assert, Freeze } from '../../utils';
import { Constant, Expression, MakeStruct } from '../../builder';
import _ from 'lodash';

export const exprToEraQL = (ast: AST.ExprIR): string => {
  switch (ast.t) {
    case 'scalar': {
      switch (typeof ast.val) {
        case 'string':
          return `'${ast.val}'`;
        case 'bigint':
        case 'boolean':
        case 'number':
          return ast.val.toString();
        case 'object': {
          if (ast.val === null) {
            const ty = escapeStringLit(displayTy(ast.ty));
            return `null_of('${ty}')`;
          }
          if (ast.val instanceof Date) {
            return exprToEraQL(
              Constant(ast.val.toISOString()).cast('timestamp').ir()
            );
          }

          if (Freeze.isReadonlyArray(ast.val)) {
            Assert.assert(ast.ty.ty.k === 'array');
            const ty = ast.ty.ty.t;
            return exprToEraQL({
              t: 'make-array',
              elements: ast.val.map((x) => Constant(x, { ty }).ir()),
            });
          }

          const struct = ast.val;

          Assert.assert(ast.ty.ty.k === 'struct');

          return exprToEraQL({
            t: 'make-struct',
            fields: _.mapValues(ast.ty.ty.fields, (ty, fieldName) => {
              const v = struct[fieldName] ?? null;
              return Constant(v, { ty }).ir();
            }),
          });
        }
        default:
          throw new Error(`LOGIC BUG => UNREACHABLE ${ast.val}`);
      }
    }
    case 'attr': {
      return `"${ast.name}"`;
    }
    case 'make-array': {
      return `[${ast.elements.map((elem) => exprToEraQL(elem)).join(', ')}]`;
    }
    case 'get-field': {
      return `get(${exprToEraQL(ast.expr)}, '${escapeStringLit(ast.name)}')`;
    }
    case 'function-call': {
      const args = ast.args.map((arg) => exprToEraQL(arg));
      switch (ast.op) {
        case '+':
        case '-':
        case '*':
        case '/':
        case '^':
        case 'and':
        case 'or':
        case '=':
        case '!=':
        case '>=':
        case '>':
        case '<=':
        case '<':
        case '||':
        case '??':
          return `(${args.join(` ${ast.op} `)})`;
      }
      return `${ast.op}(${args.join(', ')})`;
    }
    case 'cast': {
      return `cast(${exprToEraQL(ast.expr)}, '${escapeStringLit(
        displayTy(ast.targetTy)
      )}')`;
    }
    case 'make-struct': {
      return renderStruct(ast);
    }
    case 'match': {
      return `match(${exprToEraQL(ast.expr)}, ${exprToEraQL({
        t: 'make-struct',
        fields: ast.cases,
      })})`;
    }
    case 'invariants': {
      return `invariants(${exprToEraQL(ast.expr)}, ${exprToEraQL({
        t: 'make-struct',
        fields: ast.invariants,
      })})`;
    }
    case 'case': {
      const cases = ast.cases.map(({ when, then }) => {
        const ir = MakeStruct({
          when: Expression.fromAst(when),
          then: Expression.fromAst(then),
        }).ir();

        Assert.assert(ir.t === 'make-struct');

        return renderStruct(ir, (a, b) => (a > b ? -1 : 1));
      });

      const elseClause = ast.else
        ? exprToEraQL(MakeStruct({ else: Expression.fromAst(ast.else) }).ir())
        : null;

      const args = _.compact([`[${cases.join(', ')}]`, elseClause]);

      return `case(${args.join(', ')})`;
    }
    case 'window':
      throw new Error('TODO');
  }
};

const renderStruct = (
  ast: AST._MakeStruct<AST.IRChildren>,
  orderBy: (l: string, r: string) => -1 | 0 | 1 = (a, b) => (a > b ? 1 : -1)
): string => {
  const kvs = Object.entries(ast.fields)
    .sort(([l], [r]) => orderBy(l, r))
    .map(([name, val]) => `${escapeStructKey(name)}: ${exprToEraQL(val)}`)
    .join(', ');

  return `{ ${kvs} }`;
};

const escapeStructKey = (str: string): string => str;
const escapeStringLit = (str: string): string => str;
