import { z } from 'zod';
import { Contract, POST } from '@cotera/contracts';
import { llmMessageSchema, toolsSchema } from '../../llm';
import { Parser } from '@cotera/era';

export const completionParamSchema = z.object({
  model: z.string(),
  messages: llmMessageSchema.array(),
  orgId: z.string(),
  maxTokens: z.number().optional(),
  expectedType: Parser.ExtendedAttributeTypeSchema.optional(),
});

export const llmToolUseParamSchema = z.object({
  model: z.string(),
  messages: llmMessageSchema.array(),
  tools: toolsSchema,
  orgId: z.string(),
});

export const LlmContract = Contract.new({
  completion: POST({
    params: completionParamSchema,
    output: z.object({
      completion: z.string(),
    }),
    errors: z.object({
      errorType: z.enum(['LlmError', 'ValidationError']),
      message: z.string().optional(),
    }),
    mustBeAbleTo: [['write', 'warehouse']],
  }),
  useTool: POST({
    params: llmToolUseParamSchema,
    output: z.object({
      response: z.string(),
    }),
    errors: z.object({
      errorType: z.enum(['LlmError', 'ValidationError']),
      message: z.string().optional(),
    }),
    mustBeAbleTo: [['write', 'warehouse']],
  }),
  embedding: POST({
    params: z.object({
      messages: z
        .object({
          id: z.string(),
          content: z.string(),
        })
        .array(),
    }),
    output: z.object({
      embeddings: z
        .object({
          id: z.string(),
          embedding: z.number().array(),
        })
        .array(),
    }),
    errors: z.object({
      errorType: z.enum(['EmbeddingsError']),
      message: z.string().optional(),
    }),
    mustBeAbleTo: [['use', 'application']],
  }),
});
