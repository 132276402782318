import { ChildrenProps } from '@cotera/client/app/components/utils';
import { useDataGridStore } from '../context';

export const Hideable: React.FC<{ column: string } & ChildrenProps> = ({
  children,
  column,
}) => {
  const hiddenColumns = useDataGridStore((s) => s.hiddenColumns);
  if (hiddenColumns.includes(column)) {
    return null;
  }

  return children;
};
