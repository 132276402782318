import { useFuzzySearch } from '@cotera/client/app/hooks/use-fuzzy-search';
import { useDrafts } from './context';
import { filter, pluralize } from '@cotera/client/app/etc';
import { Card, List } from '@cotera/client/app/components/headless';
import { Title, Text, Badge, Button } from '@cotera/client/app/components/ui';
import { Copyable, Empty } from '@cotera/client/app/components/app';
import { classNames } from '@cotera/client/app/components/utils';
import { FocusableComponentProps } from '@cotera/client/app/components/types/form-component';
import { AssignedFilter, FormState } from './filters';
import { Feature } from '../inbox/context';

const isUnassigned = (item: Feature) =>
  item.topics.length === 0 && !item.ignored;
const isAssignedToTopic = (item: Feature, topicId: string) =>
  item.topics.some((topic) => topic.id === topicId) && !item.ignored;

export const DetectedFeatures: React.FC<{
  query?: FormState;
  assigned?: AssignedFilter;
  topicId: string;
}> = ({ query, topicId }) => {
  const features = useDrafts((s) => s.features);
  const search = useFuzzySearch(features, ['content']);
  const searchedData = search(query?.search ?? null);

  const filteredData = filter(searchedData, [
    query?.filter === 'assigned' &&
      ((items) => items.filter((item) => isAssignedToTopic(item, topicId))),
    query?.filter === 'unassigned' && ((items) => items.filter(isUnassigned)),
    query?.filter === 'all others' &&
      ((items) =>
        items.filter(
          (item) => isUnassigned(item) || !isAssignedToTopic(item, topicId)
        )),
  ]);

  return (
    <>
      <Card.Container className="h-[calc(100%-20px)]">
        <div className="flex items-center w-full justify-between px-4 pt-4 pb-3">
          <Title type="section" className="w-fit">
            Detected Features
          </Title>
          <Text.Caption>{filteredData.length} Results</Text.Caption>
        </div>
        {filteredData.length > 0 && (
          <List.Ul
            className="pl-2 pr-4 py-2 overflow-scroll h-[calc(100%-45px)]"
            hasFocus={true}
            childType={Item}
          >
            {filteredData.map((item) => {
              return (
                <Item topicId={topicId} key={item.featureId} item={item} />
              );
            })}
          </List.Ul>
        )}
        {filteredData.length === 0 && (
          <Card.Content className="h-full flex items-center justify-center">
            <Empty
              type="list"
              title="No detected features"
              caption="Try adjusting your search query or filters"
            />
          </Card.Content>
        )}
      </Card.Container>
    </>
  );
};

const Item: React.FC<
  {
    topicId: string;
    item: Feature;
  } & FocusableComponentProps
> = ({ item, ...focusProps }) => {
  const assign = useDrafts((s) => s.actions.assign);
  const focusedFeature = useDrafts((s) => s.focusedFeature);
  const selected = focusedFeature?.featureId === item.featureId;
  const setFocusedFeature = useDrafts((s) => s.actions.setFocusedFeature);

  return (
    <List.Li
      {...focusProps}
      as="li"
      onClick={() => {
        if (!selected) {
          setFocusedFeature(item);
        }
      }}
      className={classNames(
        selected ? '!border-indigo-300' : '',
        'data-[focus]:border-indigo-200 w-full justify-between transition-colors rounded px-2 py-2 flex border boder-divder mb-2 items-center'
      )}
    >
      <div className="flex flex-grow flex-col items-start relative ml-2">
        <Text.Caption className="relative">
          <Copyable offset={{ right: '-right-10', top: '-top-3' }}>
            {item.featureId}
          </Copyable>
        </Text.Caption>
        <p className="mt-1 line-clamp-2 text-sm leading-6 text-standard-text overflow-ellipsis">
          {item.content}
        </p>
      </div>
      <div className="flex items-center mb-1 justify-between">
        <div className="flex">
          {item.topics.length === 0 && (
            <Badge theme={'warning'} className="mr-2">
              Unassigned
            </Badge>
          )}
          {item.topics.map((topic) => (
            <Badge theme={'secondary'} className="mr-2">
              {topic.name}
            </Badge>
          ))}
          <Badge theme="regular" className="mr-2">
            {item.documentCount} {pluralize(item.documentCount, 'message')}
          </Badge>
        </div>
      </div>
      <Button
        theme="secondary"
        icon="plus"
        iconOnly
        tooltip="top"
        text="Add to topic"
        inline
        onClick={() => {
          assign(item);
        }}
      />
    </List.Li>
  );
};
