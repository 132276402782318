import { Ty } from '../ty';
import type { ExtendedAttributeType, Scalar } from '../ty/ty';
import type {
  AnyExprMapping,
  IRAdditionalNodes,
  IRChildren,
  MacroChildren,
  MacroVarReplacements,
} from './base';
import type { FunctionIdentifier } from './func-identifier';
import type { OrderBy } from './rel';

export type _Scalar = {
  readonly t: 'scalar';
  readonly ty: ExtendedAttributeType;
  readonly val: Scalar;
};

export type _Attribute = {
  readonly t: 'attr';
  readonly name: string;
  readonly ty: ExtendedAttributeType;
  readonly source: 'left' | 'right' | 'from';
};

export const WINDOW_OPS = [
  'rank',
  'min',
  'max',
  'row_number',
  'dense_rank',
  'percent_rank',
  'corr',
  'lead',
  'lag',
  'sum',
  'avg',
  'count',
  'first_value',
  'last_value',
  'ntile',
  'string_agg',
] as const;

export type WindowOp = (typeof WINDOW_OPS)[number];

export type WindowFrame = {
  readonly preceding: number | 'unbounded';
  readonly following: number | 'unbounded';
};

export type _Window<Children extends AnyExprMapping> = {
  readonly t: 'window';
  readonly op: WindowOp;
  readonly args: readonly Children['expr'][];
  readonly over: Over<Children>;
  readonly frame: null | WindowFrame;
};

export type Over<Children extends AnyExprMapping> = {
  readonly partitionBy: readonly Children['expr'][];
  readonly orderBy: readonly OrderBy<Children>[];
};

export type _ExprVar<Children extends AnyExprMapping> = {
  readonly t: 'expr-var';
  readonly name: string;
  readonly ty: Ty.ExtendedAttributeType;
  readonly scope: string;
  readonly default: Children['expr'] | null;
};

export type _Invariants<Children extends AnyExprMapping> = {
  readonly t: 'invariants';
  readonly expr: Children['expr'];
  readonly invariants: {
    readonly [name: string]: Children['expr'];
  };
};

export type _FunctionCall<Children extends AnyExprMapping> = {
  readonly t: 'function-call';
  readonly op: FunctionIdentifier;
  readonly args: readonly Children['expr'][];
};

export type _Cast<Children extends AnyExprMapping> = {
  readonly t: 'cast';
  readonly targetTy: Ty.AttributeType;
  readonly expr: Children['expr'];
};

export type _GetField<Children extends AnyExprMapping> = {
  readonly t: 'get-field';
  readonly name: string;
  readonly expr: Children['expr'];
};

export type _Case<Children extends AnyExprMapping> = {
  readonly t: 'case';
  readonly cases: readonly {
    readonly when: Children['expr'];
    readonly then: Children['expr'];
  }[];
  readonly else?: Children['expr'];
};

export type _Match<Children extends AnyExprMapping> = {
  readonly t: 'match';
  readonly expr: Children['expr'];
  readonly cases: Record<string, Children['expr']>;
};

export type _MakeStruct<Children extends AnyExprMapping> = {
  readonly t: 'make-struct';
  readonly fields: {
    readonly [name: string]: Children['expr'];
  };
};

export type _MakeArray<Children extends AnyExprMapping> = {
  readonly t: 'make-array';
  readonly elements: readonly Children['expr'][];
};

export type _MacroExprCase<Children extends AnyExprMapping> = {
  readonly t: 'macro-expr-case';
  readonly cases: readonly {
    readonly when: Children['expr'];
    readonly then: Children['expr'];
  }[];
  readonly else: Children['expr'];
};

export type _MacroApplyVarsToExpr<Children extends AnyExprMapping> = {
  readonly t: 'macro-apply-vars-to-expr';
  readonly vars: {
    readonly exprs: { readonly [name: string]: Children['expr'] };
  };
  readonly scope: string;
  readonly sources: { readonly from: Children['expr'] };
  readonly displayName: string | null;
};

export type AggOp = 'sum' | 'count' | 'avg' | 'min' | 'max';

export type Expr<
  Children extends AnyExprMapping,
  Additional extends AnyExprMapping
> =
  | _Scalar
  | _Attribute
  | _MakeStruct<Children>
  | _MakeArray<Children>
  | _GetField<Children>
  | _Cast<Children>
  | _Match<Children>
  | _Invariants<Children>
  | _FunctionCall<Children>
  | _Window<Children>
  | _Case<Children>
  | Additional['expr'];

export type ExprMacroNodes<Children extends AnyExprMapping> =
  | _ExprVar<Children>
  | _MacroExprCase<Children>
  | _MacroApplyVarsToExpr<Children>;

export type ExprMacroAdditional<Children extends AnyExprMapping> = {
  expr: ExprMacroNodes<Children>;
};

export type ExprIR = Expr<IRChildren, IRAdditionalNodes>;
export type ExprFR = Expr<MacroChildren, ExprMacroAdditional<MacroChildren>>;
