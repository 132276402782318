import {
  CoteraAbilities,
  DevServerError,
  ManifestCompilerError,
  Whoami,
} from '@cotera/api';
import { Main } from '@cotera/client/app/components/ui/main';
import { useAnalytics } from '@cotera/client/app/etc';
import { Assert } from '@cotera/utilities';
import {
  ExclamationTriangleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import React, { Suspense, useEffect } from 'react';
import { config } from '../config/config';
import { Text, Chip, Loading, Modal } from './components';
import { CommandPallet } from './components/app/cmdk';
import { ChildrenProps } from './components/utils';
import { makeClient, mkLocalDevServer } from './etc/api/client';
import { CoteraDuckDB } from './etc/duckdb';
import { CoteraRouter } from './router';
import { useDevMode } from './stores/dev-mode';
import { AppDataProvider, WhoamiActions, WhoamiProvider } from './stores/org';
import { ApiProvider } from './etc/data/api';
import { useSkeleton } from './etc/data/manifest';
import { RuntimeDataProvider } from './runtime-data-provider';

const ORG_STORAGE_KEY = 'COTERA_SELECTED_ORG_ID';

const appDataActions = () => ({});

const findOrg = (whoami: Whoami, orgId: string | null) => {
  return whoami.orgs?.find((org) => org.id === orgId) ?? whoami.orgs.at(0)!;
};

const whoamiOrgProperties = (whoami: Whoami, orgId: string) => {
  const org = findOrg(whoami, orgId);
  const api = makeClient(config, { 'x-org-id': org.id });

  const localDevServer = mkLocalDevServer({
    'x-org-id': org.featureFlags.UseLocalDevManifest ? 'dev' : org.id,
  });

  const abilities = CoteraAbilities.forRoles([
    ...whoami.untenantedRoles,
    ...whoami.tenantedRoles
      .filter((role) => role.orgId === org.id)
      .map(({ role }) => role),
  ]);

  return { org, api, localDevServer, abilities };
};

const whoamiActions = (): ReturnType<WhoamiActions> => {
  const changeOrg = (orgId: string) => {
    localStorage.setItem(ORG_STORAGE_KEY, orgId);
    window.location.reload();
  };
  return { changeOrg };
};

export const OrgApp = ({
  whoami,
  initedDb: db,
}: {
  whoami: Whoami;
  initedDb: Promise<CoteraDuckDB>;
}) => {
  const { identify } = useAnalytics();
  const lsOrgId = localStorage.getItem(ORG_STORAGE_KEY);
  const org = findOrg(whoami, lsOrgId);

  useEffect(() => {
    identify(org, whoami);
  }, [org, whoami, identify]);

  const orgProperties = whoamiOrgProperties(whoami, org.id);

  return (
    <WhoamiProvider
      state={{ whoami, ...orgProperties }}
      actions={whoamiActions}
    >
      <ApiProvider
        api={orgProperties.api}
        localDevServer={orgProperties.localDevServer}
      >
        <Suspense
          fallback={
            <Main>
              <Loading.Dots />
            </Main>
          }
        >
          <OrgDataApp initedDb={db}>
            <CoteraRouter />
          </OrgDataApp>
        </Suspense>
      </ApiProvider>
    </WhoamiProvider>
  );
};

export const OrgDataApp: React.FC<
  ChildrenProps & { initedDb: Promise<CoteraDuckDB> }
> = ({ children, initedDb: db }) => {
  const { data: result } = useSkeleton();

  if (result.isErr()) {
    return (
      <DevServerErrorScreen error={result.error as unknown as DevServerError} />
    );
  }

  return (
    <AppDataProvider
      key={result.value.version}
      state={{ skeleton: result.value, initedDb: db }}
      actions={appDataActions}
    >
      <CommandPallet />
      <RuntimeDataProvider>{children}</RuntimeDataProvider>
    </AppDataProvider>
  );
};

const DevServerErrorScreen: React.FC<{
  error: DevServerError | ManifestCompilerError;
}> = ({ error }) => {
  const devMode = useDevMode();

  let inner: React.ReactNode;

  switch (error.errorType) {
    case 'CompileError':
      inner = (
        <pre className="text-left bg-accent-background w-full p-4 px-8 text-alt-text">
          {error.stackTrace}
        </pre>
      );
      break;
    case 'OrgNotFound':
    case 'NoDevServerFound':
      inner = null;
      break;
    default:
      return Assert.unreachable(error);
  }

  return (
    <Modal open priority="highest" onOpenChange={() => {}} padding={false}>
      <div className="flex flex-col p-4 pt-6 min-w-[500px] items-center">
        <ExclamationTriangleIcon className="text-rose-400 w-12 h-12" />
        <Text.H3 className="flex items-center pb-2 text-alt-text">
          Dev Server Error
        </Text.H3>
        <div className="flex items-center justify-center">
          <Chip type="negative" className="w-fit mr-4">
            {error.errorType}
          </Chip>
          <Text.Caption className="">
            For info on how dev mode works, click here.
          </Text.Caption>
        </div>
      </div>
      <div className="flex flex-col items-center text-center text-sm text-standard-text">
        {inner}
      </div>
      <button
        onClick={() => devMode.setEnabled(false)}
        className="focus:outline-none flex items-center justify-center focus:ring-0 bg-rose-400 px-3 py-3 w-full text-alt-text hover:bg-rose-500 text-sm"
      >
        <XCircleIcon className="h-5 w-5 mr-2" /> Turn Off Dev Mode
      </button>
    </Modal>
  );
};
