import { err, ok } from 'neverthrow';
import { combineMeta, TokenStream, WithMeta } from '../lex';
import { EraQlAst, ParseRes, AssocKV } from './ast';
import { infixBindingPower, postfixBindingPower } from './binding-power';
import { Assert } from '../../utils';

export const buildEraQLAst = (
  stream: TokenStream,
  minBindingPower: number
): ParseRes<WithMeta<EraQlAst> | null> => {
  const base = stream.skipWhiteSpace().consume();

  if (base === null) {
    return ok(null);
  }

  const [lhsToken, lhsMeta] = base;

  let lhs: WithMeta<EraQlAst>;

  originalLhs: switch (lhsToken.t) {
    case 'symbol':
    case 'ident':
    case 'literal': {
      lhs = [lhsToken, lhsMeta];
      break originalLhs;
    }
    case 'grouping': {
      switch (lhsToken.op) {
        case '(': {
          const child = buildEraQLAst(stream, 0);

          if (child.isErr()) {
            return err(child.error);
          }

          if (child.value === null) {
            return err([{ t: 'expected', expected: 'expression' }, lhsMeta]);
          }

          const closingBrace = stream
            .skipWhiteSpace()
            .consumeIf((t) => t.t === 'grouping' && t.op === ')');

          if (closingBrace.isErr()) {
            return err([{ t: 'unclosed-deliminator', missing: ')' }, lhsMeta]);
          }

          lhs = child.value;
          break originalLhs;
        }
        case '[': {
          const immediateClosingBracket = stream
            .skipWhiteSpace()
            .consumeIf((t) => t.t === 'grouping' && t.op === ']');

          if (immediateClosingBracket.isOk()) {
            lhs = [
              { t: 'list', elems: [] },
              combineMeta(lhsMeta, immediateClosingBracket.value[1]),
            ];
            break originalLhs;
          }

          const children = doParseList(stream);

          if (children.isErr()) {
            return err(children.error);
          }

          const closingBracket = stream
            .skipWhiteSpace()
            .consumeIf((t) => t.t === 'grouping' && t.op === ']');

          if (closingBracket.isErr()) {
            return err([{ t: 'unclosed-deliminator', missing: ']' }, lhsMeta]);
          }

          lhs = [
            { t: 'list', elems: children.value },
            combineMeta(
              lhsMeta,
              ...children.value.map((x) => x[1]),
              closingBracket.value[1]
            ),
          ];

          break originalLhs;
        }
        case '{': {
          const kvs = doParseKvs(stream);

          if (kvs.isErr()) {
            return err(kvs.error);
          }

          const closingBrace = stream
            .skipWhiteSpace()
            .consumeIf((t) => t.t === 'grouping' && t.op === '}');

          if (closingBrace.isErr()) {
            return err([{ t: 'unclosed-deliminator', missing: '}' }, lhsMeta]);
          }

          lhs = [
            { t: 'assoc', kvs: kvs.value },
            combineMeta(
              lhsMeta,
              ...kvs.value.map((kv) => kv[1]),
              closingBrace.value[1]
            ),
          ];

          break originalLhs;
        }
        default:
          return err([{ t: 'expected', expected: 'expression' }, lhsMeta]);
      }
    }
    default:
      return err([{ t: 'unexpected-token' }, lhsMeta]);
  }

  operatorLoop: while (!stream.skipWhiteSpace().isFinished()) {
    const next = stream.skipWhiteSpace().peak();

    if (next === null) {
      break operatorLoop;
    }

    const pf = postfixBindingPower(next);

    if (pf !== null) {
      const [leftBindingPower, _rightBindingPower] = pf;

      if (leftBindingPower < minBindingPower) {
        break operatorLoop;
      }

      stream.consume();
      const [nextToken, nextMeta] = next;

      if (
        nextToken.t !== 'operator' &&
        nextToken.t !== 'symbol' &&
        nextToken.t !== 'tag'
      ) {
        return err([{ t: 'cant-call' }, nextMeta]);
      }

      lhs = [
        { t: 'call', op: [nextToken, nextMeta], args: [lhs] },
        combineMeta(nextMeta, lhs[1]),
      ];

      continue operatorLoop;
    }

    if (next[0].t === 'grouping' && next[0].op === '(') {
      stream.consume();

      const immediateClosingBrace = stream
        .skipWhiteSpace()
        .consumeIf((t) => t.t === 'grouping' && t.op === ')');

      if (immediateClosingBrace.isOk()) {
        lhs = [
          { t: 'call', op: lhs, args: [] },
          combineMeta(next[1], lhs[1], immediateClosingBrace.value[1]),
        ];

        continue operatorLoop;
      }

      const list = doParseList(stream);

      if (list.isErr()) {
        return err(list.error);
      }

      const closingBrace = stream
        .skipWhiteSpace()
        .consumeIf((t) => t.t === 'grouping' && t.op === ')');

      if (closingBrace.isErr()) {
        return err([{ t: 'unclosed-deliminator', missing: ')' }, next[1]]);
      }

      lhs = [
        { t: 'call', op: lhs, args: list.value },
        combineMeta(
          lhs[1],
          next[1],
          ...list.value.map((x) => x[1]),
          closingBrace.value[1]
        ),
      ];

      continue operatorLoop;
    }

    const inF = infixBindingPower(next);

    if (inF !== null) {
      const [leftBindingPower, rightBindingPower] = inF;

      if (leftBindingPower < minBindingPower) {
        break operatorLoop;
      }

      stream.consume();
      const rhs = buildEraQLAst(stream, rightBindingPower);

      if (rhs.isErr()) {
        return err(rhs.error);
      }

      if (rhs.value === null) {
        break operatorLoop;
      }

      const [nextToken, nextMeta] = next;

      if (
        nextToken.t !== 'operator' &&
        nextToken.t !== 'symbol' &&
        nextToken.t !== 'tag'
      ) {
        return err([{ t: 'cant-call' }, nextMeta]);
      }

      lhs = [
        { t: 'call', args: [lhs, rhs.value], op: [nextToken, nextMeta] },
        combineMeta(lhs[1], rhs.value[1]),
      ];

      continue operatorLoop;
    }

    break;
  }

  return ok(lhs);
};

const doParseKvs = (stream: TokenStream): ParseRes<WithMeta<AssocKV>[]> => {
  const kvs: WithMeta<AssocKV>[] = [];
  const first = doParseKv(stream);

  if (first.isErr()) {
    return err(first.error);
  }

  kvs.push(first.value);

  // eslint-disable-next-line no-constant-condition
  kvLoop: while (!stream.skipWhiteSpace().isFinished()) {
    const comma = stream.consumeIf((t) => t.t === 'grouping' && t.op === ',');

    if (comma.isErr()) {
      break kvLoop;
    }
    const kv = doParseKv(stream);

    if (kv.isErr()) {
      return err(kv.error);
    }

    kvs.push(kv.value);
  }

  return ok(kvs);
};

const doParseKv = (stream: TokenStream): ParseRes<WithMeta<AssocKV>> => {
  const key = buildEraQLAst(stream, 0);

  if (key.isErr()) {
    return err(key.error);
  }

  if (key.value === null) {
    throw new Error('UNREACHABLE? empty in a map key');
  }

  let sym: string;
  const [keyToken, keyMeta] = key.value;

  switch (keyToken.t) {
    case 'symbol':
      sym = keyToken.sym;
      break;
    case 'ident':
      sym = keyToken.name;
      break;
    case 'literal':
      if (
        typeof keyToken.val === 'string' ||
        typeof keyToken.val === 'number'
      ) {
        sym = keyToken.val.toString();
      } else {
        return err([{ t: 'expected', expected: 'struct-key' }, keyMeta]);
      }
      break;
    case 'call':
    case 'list':
    case 'assoc':
      return err([{ t: 'expected', expected: 'struct-key' }, keyMeta]);
    default:
      return Assert.unreachable(keyToken);
  }

  const sep = stream.consumeIf((t) => t.t === 'operator' && t.op === ':');

  if (sep.isErr()) {
    const next = stream.peak();
    Assert.assert(next !== null);
    return err([{ t: 'expected', expected: [':'] }, next[1]]);
  }

  const val = buildEraQLAst(stream, 0);

  if (val.isErr()) {
    return err(val.error);
  }

  if (val.value === null) {
    throw new Error('UNREACHABLE? empty in a map key');
  }

  return ok([
    { key: [sym, keyMeta], val: val.value },
    combineMeta(keyMeta, sep.value[1], val.value[1]),
  ]);
};

const doParseList = (stream: TokenStream): ParseRes<WithMeta<EraQlAst>[]> => {
  const elements: WithMeta<EraQlAst>[] = [];

  elementLoop: while (!stream.skipWhiteSpace().isFinished()) {
    const nextExpr = buildEraQLAst(stream, 0);

    if (nextExpr.isErr()) {
      return err(nextExpr.error);
    }

    if (nextExpr.isOk()) {
      if (nextExpr.value !== null) {
        elements.push(nextExpr.value);
      }
    }

    stream.skipWhiteSpace();

    if (stream.consumeIf((t) => t.t === 'grouping' && t.op === ',').isOk()) {
      continue;
    } else {
      break elementLoop;
    }
  }

  return ok(elements);
};
