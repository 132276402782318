import { Relation, TC, Ty } from '@cotera/era';
import { DataGridRegistry } from './registry-impl';
import {
  AsyncHeaderWrapper,
  BaseHeader,
  CategoricalHeader,
  ContinuousHeader,
  TimestampHeader,
} from './components/spark';
import { Cell } from './components/cell';
import {
  DateColumnState,
  NumbericColumnState,
  useColumnState,
} from './column-state';
import { numberFormatter } from '../utils/format/number';
import { timeSeries } from '../utils/format/time-series';
import { UTCDate } from '../utils';
import { FFI } from '@cotera/sdk/core';
import { Button, Tooltip } from '@cotera/client/app/components/ui';
import { useState } from 'react';
import { RunTopicMatchingView } from './ffi/run-topic-matching';
import { PauseSinkButton } from './ffi/pause-sink';
import { UddWriteButton } from './ffi/udd-write';
import { SnapshotSinkButton } from '../data-vis/data-grid/ffi-actions/snapshot-sink';
import { SetSinkCursorForm } from './ffi/set-sink-cursor';
import { Link } from 'react-router-dom';

export const defaultRegistry = new DataGridRegistry()
  .add((_column, ty) => isLink(ty), {
    header: (rel, column, ty) => {
      return (
        <BaseHeader
          attr={{
            name: column,
            ty: 'link',
          }}
        >
          <AsyncHeaderWrapper>
            <CategoricalHeader
              rel={rel.select((t) => ({
                [column]: t.attr(column).getField('__link').cast('string'),
              }))}
              attr={{ name: column, ty }}
            />
          </AsyncHeaderWrapper>
        </BaseHeader>
      );
    },
    cell: (column, _ty, value) => {
      const { __link, text, action } = value as {
        __link: string;
        text: string;
        action: 'tab' | 'navigate';
      };
      return (
        <Cell column={column}>
          <Link
            className="justify-between flex hover:text-primary-text"
            to={__link}
            target={action === 'tab' ? '_blank' : undefined}
          >
            {text}
          </Link>
        </Cell>
      );
    },
  })
  .add(
    (_column, ty) =>
      FFI.isFFI(ty) && ty.tags.includes(FFI.FFI_FUNC_NAMES.TOPIC_MATCHING),
    {
      header: (rel, column, ty) => {
        return <TopicMatchingHeader rel={rel} column={column} ty={ty} />;
      },
      cell: (column, _ty, value) => {
        const { params } = value as { params: { content: string } };
        return <Cell column={column}>{params.content}</Cell>;
      },
    }
  )
  .add((_column, ty) => FFI.isFFI(ty), {
    header: (_rel, column) => (
      <BaseHeader
        attr={{
          name: column,
          ty: 'action',
        }}
      ></BaseHeader>
    ),
    cell: (column, ty, value) => {
      if (ty.tags.includes(FFI.FFI_FUNC_NAMES.PAUSE_SINK)) {
        const { params } = value as {
          params: { entityId: string; streamId: string; sinkId: string };
        };
        return (
          <Cell column={column}>
            <PauseSinkButton {...params} />
          </Cell>
        );
      }

      if (ty.tags.includes(FFI.FFI_FUNC_NAMES.OPEN_LINK)) {
        const { params } = value as { params: { to: string; label: string } };
        return (
          <Cell column={column}>
            <Button
              text={params.label}
              onClick={() => window.open(params.to)}
            />
          </Cell>
        );
      }

      if (ty.tags.includes(FFI.FFI_FUNC_NAMES.UUD_WRITE)) {
        const { params } = value as {
          params: {
            entity_name: string;
            key: string;
            identifier: number | string;
            options: {
              value: Ty.Scalar | string;
              label: string;
              color: string;
            }[];
          };
        };
        return (
          <Cell column={column}>
            <UddWriteButton payload={params} />
          </Cell>
        );
      }

      if (ty.tags.includes(FFI.FFI_FUNC_NAMES.SNAPSHOT)) {
        const { params } = value as {
          params: { entityId: string; streamId: string };
        };
        return (
          <Cell column={column}>
            <SnapshotSinkButton {...params} />
          </Cell>
        );
      }

      if (ty.tags.includes(FFI.FFI_FUNC_NAMES.SINK_CURSOR)) {
        const { params } = value as {
          params: {
            detectedAt: Date;
            coteraStableId: string;
            entityId: string;
            streamId: string;
            sinkId: string;
          };
        };
        return (
          <Cell column={column}>
            <SetSinkCursorForm {...params} />
          </Cell>
        );
      }

      return <Cell column={column}>{JSON.stringify(value)}</Cell>;
    },
  })
  .add((_column, ty) => TC.isId(ty), {
    header: (rel, column, ty) => (
      <BaseHeader
        attr={{
          name: column,
          ty: Ty.displayTy(ty),
        }}
      >
        <AsyncHeaderWrapper>
          <CategoricalHeader rel={rel} attr={{ name: column, ty }} />
        </AsyncHeaderWrapper>
      </BaseHeader>
    ),
  })
  .add((_column, ty) => TC.isTimestamp(ty), {
    header: (rel, column, ty) => (
      <BaseHeader
        attr={{
          name: column,
          ty: 'timestamp',
        }}
      >
        <AsyncHeaderWrapper>
          <TimestampHeader rel={rel} attr={{ name: column, ty }} />
        </AsyncHeaderWrapper>
      </BaseHeader>
    ),
    cell: (column, _ty, value) => (
      <DateCell value={new UTCDate(String(value))} column={column} />
    ),
  })
  .add((_column, ty) => TC.isNumeric(ty), {
    header: (rel, column, ty) => (
      <BaseHeader
        attr={{
          name: column,
          ty: mapType(ty),
        }}
      >
        <AsyncHeaderWrapper>
          <ContinuousHeader rel={rel} attr={{ name: column, ty }} />
        </AsyncHeaderWrapper>
      </BaseHeader>
    ),
    cell: (column, _ty, value) => (
      <NumbericCell value={Number(value)} column={column} />
    ),
  })
  .add((_column, ty) => TC.isStringLike(ty), {
    header: (rel, column, ty) => (
      <BaseHeader
        attr={{
          name: column,
          ty: 'string',
        }}
      >
        <AsyncHeaderWrapper>
          <CategoricalHeader rel={rel} attr={{ name: column, ty }} />
        </AsyncHeaderWrapper>
      </BaseHeader>
    ),
  })
  .add((_column, ty) => TC.isBoolean(ty), {
    header: (rel, column, ty) => (
      <BaseHeader
        attr={{
          name: column,
          ty: 'boolean',
        }}
      >
        <AsyncHeaderWrapper>
          <CategoricalHeader
            rel={rel.select((t) => ({
              [column]: t.attr(column).cast('string'),
            }))}
            attr={{ name: column, ty }}
          />
        </AsyncHeaderWrapper>
      </BaseHeader>
    ),
  });

const NumbericCell: React.FC<{ value: number; column: string }> = ({
  value,
  column,
}) => {
  const [state] = useColumnState<NumbericColumnState>(column);

  return (
    <Cell column={column} className="text-right">
      {numberFormatter(value, undefined, state.decimals)}
    </Cell>
  );
};

const DateCell: React.FC<{ value: Date; column: string }> = ({
  value,
  column,
}) => {
  const [state] = useColumnState<DateColumnState>(column);

  return <Cell column={column}>{timeSeries(value, state.format)}</Cell>;
};

const mapType = (t: Ty.ExtendedAttributeType): React.ReactNode => {
  const ty = Ty.shorthandToTy(t);

  const full = Ty.displayTy(ty);

  const inner = ty.ty.k === 'primitive' ? ty.ty.t : ty.ty.k;

  if (ty.ty.k === 'primitive') {
    return <span>{inner}</span>;
  } else {
    return (
      <Tooltip side="top" text={full}>
        <span>{inner}</span>
      </Tooltip>
    );
  }
};

const TopicMatchingHeader: React.FC<{
  rel: Relation;
  column: string;
  ty: Ty.ExtendedAttributeType;
}> = ({ rel, column, ty }) => {
  const [showRunModal, setShowRunModal] = useState(false);

  return (
    <>
      <BaseHeader
        attr={{
          name: column,
          ty: 'topic',
        }}
      >
        <div className="flex items-start w-full">
          <div className="w-full">
            <AsyncHeaderWrapper>
              <CategoricalHeader
                rel={rel.select((t) => ({
                  [column]: t
                    .attr(column)
                    .getField('params')
                    .get('content')
                    .cast('string'),
                }))}
                attr={{ name: column, ty }}
              />
            </AsyncHeaderWrapper>
          </div>
          <Button
            icon="play"
            iconOnly
            tooltip="bottom"
            theme="secondary"
            text="Run draft topic generation"
            className="ml-2 w-10"
            overrides={{ height: 'h-8' }}
            onClick={() => {
              setShowRunModal(true);
            }}
          />
        </div>
      </BaseHeader>
      <RunTopicMatchingView
        show={showRunModal}
        onClose={() => setShowRunModal(false)}
        column={column}
      />
    </>
  );
};

export const isLink = (attr: Ty.ExtendedAttributeType) => {
  const ty = Ty.shorthandToTy(attr);
  return ty.ty.k === 'struct' && '__link' in ty.ty.fields;
};
