import { makeStore, StateGetter, StateSetter } from '@cotera/client/app/etc';
import { Relation } from '@cotera/era';
import React from 'react';
import { ChildrenProps } from '../../utils/helpers';
import { Assert } from '@cotera/utilities';
import { v4 } from 'uuid';
import { mapValues, startCase } from 'lodash';

export type NodeTransform = {
  t: 'filter' | 'group-by' | 'limit' | 'select' | 'order-by';
  fn: (rel: Relation) => Relation;
  fromNodeId: string;
};

type BaseNode = {
  name: string;
  id: string;
  position: { x: number; y: number };
};

type FilterNode = {
  t: 'filter';
  parentId: string;
  transforms: NodeTransform[];
} & BaseNode;

type SampleNode = {
  t: 'sample';
  parentId: string;
  transforms: NodeTransform[];
} & BaseNode;

type ChartNode = {
  t: 'chart';
  parentId: string;
  type: 'line' | 'bar' | 'pie';
  transforms: NodeTransform[];
} & BaseNode;

type GroupByNode = {
  t: 'group-by';
  parentId: string;
  transforms: NodeTransform[];
} & BaseNode;

type DatasetNode = {
  parentId: null;
  t: 'dataset';
  type: 'full' | 'sample';
  rel: TransformableRelation;
  artifactRel?: TransformableRelation;
  transforms: NodeTransform[];
} & BaseNode;

export type WorkspaceNode =
  | SampleNode
  | FilterNode
  | GroupByNode
  | DatasetNode
  | ChartNode;

type State = {
  nodes: Record<string, WorkspaceNode>;
};

export class TransformableRelation extends Relation {
  public constructor(ast: Relation['ast'], typecheck: Relation['typecheck']) {
    super(ast, typecheck);
  }

  apply = (fns: NodeTransform[]) => {
    let rel = new Relation(this.ast, this.typecheck);

    for (const fn of fns) {
      rel = fn.fn(rel);
    }

    return rel;
  };
}

const actions = (set: StateSetter<State>, get: StateGetter<State>) => ({
  getDatasetForNode: (node: WorkspaceNode) => {
    return getDatasetForNode(get().nodes, node, []);
  },
  updateNode: (id: string, node: Partial<WorkspaceNode>) =>
    set((state) => {
      const workspaceNode = state.nodes[id];
      Assert.assert(
        workspaceNode !== undefined,
        `Node with id ${id} not found`
      );
      return {
        nodes: {
          ...get().nodes,
          [id]: {
            ...workspaceNode,
            ...node,
          } as WorkspaceNode,
        },
      };
    }),
  getChildNodes: (node: WorkspaceNode): WorkspaceNode[] => {
    //recursively get all children of a node
    const children = Object.values(get().nodes).filter(
      (n) => n.parentId === node.id
    );
    return children.flatMap((c) => [c, ...actions(set, get).getChildNodes(c)]);
  },
  registerArtifact: (
    id: string,
    artifact: {
      id: string | null;
      rel: Relation;
      sourceHash: string;
    }
  ) => {
    const datasetNode = getDatasetForNode(get().nodes, get().nodes[id]!, []);

    set((state) => ({
      nodes: {
        ...state.nodes,
        [datasetNode.id]: {
          ...state.nodes[datasetNode.id]!,
          artifactRel: new TransformableRelation(
            artifact.rel.ast,
            artifact.rel.typecheck
          ),
        },
      },
    }));
  },
  addNode: (node: Omit<WorkspaceNode, 'id' | 'name'>) =>
    set((state) => {
      const id = v4();
      const workspaceNode = { ...node, id } as WorkspaceNode;
      const nodeTransforms = getAllTransformsForNode(
        workspaceNode,
        state.nodes
      );
      const nodes = get().nodes;
      const nodesOfType = Object.values(nodes).filter((n) => n.t === node.t);

      return {
        nodes: {
          ...state.nodes,
          [id]: {
            ...workspaceNode,
            transforms: nodeTransforms,
            name: `${startCase(node.t)} ${nodesOfType.length + 1}`,
          },
        },
      };
    }),
  node: (id: string) => {
    return {
      filter: (where: (rel: Relation) => Relation) => {
        const node = get().nodes[id];
        Assert.assert(node !== undefined, `Node with id ${id} not found`);
        const filterNode = getNearestNodeOfType(node, get().nodes, 'filter');
        Assert.assert(
          filterNode !== undefined,
          `No filter node found for node with id ${id}`
        );

        const nodeTransforms = getAllTransformsForNode(node, get().nodes);
        const transforms = [
          ...nodeTransforms.slice(0, -1),
          { t: 'filter' as const, fn: where, fromNodeId: filterNode.id },
        ];

        const children = actions(set, get).getChildNodes(filterNode);
        //for each child, update its rel and transforms

        const newNodes = {
          ...get().nodes,
          [filterNode.id]: {
            ...filterNode,
            transforms,
          },
        };

        const childNodes = Object.fromEntries(
          children.map((c) => {
            return [
              c.id,
              {
                ...c,
                transforms: getAllTransformsForNode(c, newNodes),
              },
            ];
          })
        );

        set(() => {
          return {
            nodes: {
              ...newNodes,
              ...childNodes,
            },
          };
        });
      },
      transform: (
        fn: (rel: Relation) => Relation,
        type: NodeTransform['t'] = 'select'
      ) => {
        const node = get().nodes[id];
        Assert.assert(node !== undefined, `Node with id ${id} not found`);

        const nodeTransforms = getAllTransformsForNode(node, get().nodes);

        const transforms = nodeTransforms.some(
          (t) => t.fromNodeId === node.id && t.t === type
        )
          ? nodeTransforms.map((t) => {
              if (t.fromNodeId === node.id && t.t === type) {
                return { t: type, fn, fromNodeId: node.id };
              }
              return t;
            })
          : [...nodeTransforms, { t: type, fn, fromNodeId: node.id }];

        const children = actions(set, get).getChildNodes(node);
        //for each child, update its rel and transforms

        const newNodes = {
          ...get().nodes,
          [node.id]: {
            ...node,
            transforms,
          },
        };

        const childNodes = Object.fromEntries(
          children.map((c) => {
            return [
              c.id,
              {
                ...c,
                transforms: getAllTransformsForNode(c, newNodes),
              },
            ];
          })
        );

        set(() => {
          return {
            nodes: {
              ...newNodes,
              ...childNodes,
            },
          };
        });
      },
      groupBy: (groupBy: (rel: Relation) => Relation) => {
        const node = get().nodes[id];
        Assert.assert(node !== undefined, `Node with id ${id} not found`);
        const groupByNode = getNearestNodeOfType(node, get().nodes, 'group-by');
        Assert.assert(
          groupByNode !== undefined,
          `No group by node found for node with id ${id}`
        );

        const nodeTransforms = getAllTransformsForNode(node, get().nodes);
        const transforms = [
          ...nodeTransforms.slice(0, -1),
          { t: 'group-by' as const, fn: groupBy, fromNodeId: groupByNode.id },
        ];

        const children = actions(set, get).getChildNodes(groupByNode);
        //for each child, update its rel and transforms

        const newNodes = {
          ...get().nodes,
          [groupByNode.id]: {
            ...groupByNode,
            transforms,
          },
        };

        const childNodes = Object.fromEntries(
          children.map((c) => {
            return [
              c.id,
              {
                ...c,
                transforms: getAllTransformsForNode(c, newNodes),
              },
            ];
          })
        );

        set(() => {
          return {
            nodes: {
              ...newNodes,
              ...childNodes,
            },
          };
        });
      },
    };
  },
});

type Actions = ReturnType<typeof actions>;

export const { hook: useWorkspace, provider: Provider } = makeStore<
  State,
  Actions
>();

export const WorkspaceProvider: React.FC<
  { nodes: Record<string, WorkspaceNode> } & ChildrenProps
> = ({ nodes, children }) => {
  return (
    <Provider
      state={{
        nodes: mapValues(nodes, (n) => {
          const transforms = getAllTransformsForNode(n, nodes);
          return { ...n, transforms };
        }),
      }}
      actions={actions}
    >
      {children}
    </Provider>
  );
};

export const getDatasetForNode = (
  nodes: Record<string, WorkspaceNode>,
  node: WorkspaceNode,
  transforms: NodeTransform[]
): {
  rel: TransformableRelation;
  artifactRel?: TransformableRelation;
  type: 'full' | 'sample';
  id: string;
} => {
  if (node.t === 'dataset') {
    return {
      rel: node.rel,
      artifactRel: node.artifactRel,
      type: node.type,
      id: node.id,
    };
  } else {
    const parentNode = nodes[node.parentId];
    Assert.assert(
      parentNode !== undefined,
      `Parent node with id ${node.parentId} not found`
    );
    return getDatasetForNode(nodes, nodes[node.parentId]!, [
      ...node.transforms,
      ...transforms,
    ]);
  }
};

export const useNodeRel = (
  node: WorkspaceNode,
  props: { canUseSample: boolean } = { canUseSample: true }
) => {
  const transforms = useWorkspace((s) => s.nodes[node.id]!.transforms);
  const parentTransforms =
    useWorkspace((s) =>
      node.parentId ? s.nodes[node.parentId]?.transforms : []
    ) ?? [];

  const dataset = useWorkspace((s) => s.actions.getDatasetForNode);

  const { rel, artifactRel, type } = dataset(node);

  const targetRel =
    type === 'sample' && props.canUseSample && artifactRel ? artifactRel : rel;
  const alteredRel = targetRel.apply(transforms);

  const baseRel = targetRel.apply(parentTransforms);

  return { rel: alteredRel, baseRel, type, artifactRel };
};

export const useNodeBaseRel = (
  node: WorkspaceNode,
  props: { canUseSample: boolean } = { canUseSample: true }
) => {
  const parentTransforms =
    useWorkspace((s) =>
      node.parentId ? s.nodes[node.parentId]?.transforms : []
    ) ?? [];

  const dataset = useWorkspace((s) => s.actions.getDatasetForNode);

  const { rel, artifactRel, type } = dataset(node);

  const targetRel =
    type === 'sample' && props.canUseSample && artifactRel ? artifactRel : rel;

  const baseRel = targetRel.apply(parentTransforms);

  return { baseRel, type, artifactRel };
};

const getNearestNodeOfType = (
  node: WorkspaceNode,
  nodes: Record<string, WorkspaceNode>,
  type: WorkspaceNode['t']
): WorkspaceNode | undefined => {
  if (node.t === type) {
    return node;
  }

  if (node.parentId === null) {
    return undefined;
  }
  const parent = nodes[node.parentId];
  if (parent === undefined) {
    return undefined;
  }

  return getNearestNodeOfType(parent, nodes, type);
};

const getAllTransformsForNode = (
  node: WorkspaceNode,
  nodes: Record<string, WorkspaceNode>
): NodeTransform[] => {
  if (node.t === 'dataset') {
    return node.transforms;
  } else {
    return [
      ...getAllTransformsForNode(nodes[node.parentId]!, nodes),
      ...node.transforms.filter((x) => x.fromNodeId === node.id),
    ];
  }
};
