import { Column, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useDataGridStore } from '../context';
import { useNearBottom } from './use-near-bottom';
import { useMemo, useRef } from 'react';
import { Relation } from '@cotera/era';
import { Registry } from '../types';

export const useDataGridSetup = ({
  rel,
  registry,
}: {
  rel: Relation;
  registry: Registry;
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const columns = useMemo(
    () =>
      Object.entries(rel.attributes).map(([name, attr]) => {
        const column = {
          header: registry.getHeader(rel, name, attr),
          accessorKey: name,
        } as unknown as Column<any, unknown>;

        return column;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rel.sqlHash(), registry]
  );

  const table = useReactTable({
    initialState: {
      pagination: {
        pageSize: 20,
        pageIndex: 0,
      },
    },
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    data: [], //we manage the data ourselves
    columns: [...columns],
    defaultColumn: {
      minSize: 120,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  const setDataLength = useDataGridStore((s) => s.actions.setDataLength);
  const dataLength = useDataGridStore((s) => s.dataLength);
  const totalRows = useDataGridStore((s) => s.totalRows);

  useNearBottom(
    tableContainerRef,
    () => {
      if (totalRows > dataLength) {
        setDataLength(dataLength + 50);
      }
    },
    100
  );

  return {
    containerRef: tableContainerRef,
    controller: table,
  };
};
