import {
  useMutation,
  useQueryClient,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useAppData, useTenantedClient, useWhoami } from '../stores/org';
import { queryKeys } from './query-cache-keys';
import { Assert } from '@cotera/utilities';
import { useSuspenseQuery } from '@tanstack/react-query';
import { TC, Ty } from '@cotera/era';
import { useTenantedQueryKey } from './use-tenanted-query-key';

export const useIdeConfig = () => {
  const orgId = useWhoami((s) => s.org.id);
  const client = useTenantedClient();
  return useSuspenseQuery({
    queryKey: queryKeys.ideConfig({ orgId }),
    queryFn: async () => Assert.assertOk(await client.config.config({})),
  });
};

export const useCreateEntity = () => {
  const client = useTenantedClient();
  const queryClient = useQueryClient();
  const orgId = useWhoami((s) => s.org.id);

  return useMutation({
    mutationFn: client.config.createEntityDefinition,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.ideConfig({ orgId }),
      }),
  });
};

export const useEntity = ({ entityName }: { entityName: string }) => {
  const {
    data: { entities },
  } = useIdeConfig();
  const definitions = useAppData((s) => s.skeleton.definitions);
  const matchedEntity = entities.find((x) => x.name === entityName)!;

  Assert.assert(matchedEntity !== undefined, `Entity ${entityName} not found`);

  return {
    ...matchedEntity,
    definitions: Object.fromEntries(
      Object.entries(definitions).filter(([_, def]) =>
        Object.values(def.attributes).some((attr) =>
          TC.implementsTy({ subject: attr, req: matchedEntity.idType })
        )
      )
    ),
  };
};

export const useEntityColumns = ({
  entityName,
}: {
  entityName: string;
}): UseSuspenseQueryResult<
  {
    key: string;
    ty: Ty.ExtendedAttributeType;
    id: string;
  }[]
> => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey((orgId) =>
    queryKeys.udds({ orgId, entityName })
  );

  return useSuspenseQuery({
    queryFn: async () => {
      return Assert.assertOk(await client.udds.get({ entityName })).udds;
    },
    queryKey,
  });
};
