import { err } from 'neverthrow';
import { Assert } from '../../../utils';
import { combineMeta } from '../../lex';
import { Call } from '../ast';
import { compileAst } from '../compile';
import { TY_SPECIAL_FORMS } from './ty-special-form';
import { SpecialForm } from './types';
import { EXPR_SPECIAL_FORMS } from './expr-special-forms';

const pipe: SpecialForm = ([lhs, call], ctx) => {
  Assert.assert(
    lhs !== undefined && call !== undefined,
    'Artiy already checked'
  );

  const [_lhsAst, lhsMeta] = lhs;
  const [callAst, callMeta] = call;

  switch (callAst.t) {
    case 'call': {
      const newCall: Call = { ...callAst, args: [lhs, ...callAst.args] };
      return compileAst([newCall, combineMeta(lhsMeta, callMeta)], ctx);
    }
    case 'symbol': {
      const newCall: Call = { t: 'call', op: [callAst, callMeta], args: [lhs] };
      return compileAst([newCall, combineMeta(lhsMeta, callMeta)], ctx);
    }
    default:
      return err([{ t: 'cant-pipe-into' }, callMeta]);
  }
};

export const SPECIAL_FORMS: Record<
  string,
  [artiy: number | number[] | 'any', fn: SpecialForm]
> = {
  '|>': [2, pipe],
  ...EXPR_SPECIAL_FORMS,
  ...TY_SPECIAL_FORMS,
};
