import { useState } from 'react';
import {
  Button,
  Loading,
  Modal,
  toast,
} from '@cotera/client/app/components/ui';
import { H3 } from '../../../ui/typography';
import { classNames } from '../../../utils';
import {
  Assumption,
  useAssumptionBuilder,
} from './hooks/use-assumptions-builder';
import { useWarehouseDialect } from '@cotera/client/app/hooks';
import { useIdeConfig } from '@cotera/client/app/hooks/entities';
import { useMutation } from '@tanstack/react-query';
import { useApi } from '@cotera/client/app/etc/data/api';

const CODE_STYLE_CLASS =
  'max-w-7xl p-2 rounded bg-gray-100 border border-divider font-mono overflow-auto my-2';

export const SetAssumptions: React.FC<{
  entity_name: string;
  table_name: string;
  table_schema: string;
  candidates: {
    column_name: string;
    data_type: string;
    is_nullable: boolean;
  }[];
}> = ({ candidates, table_name, table_schema, entity_name }) => {
  const { t } = useApi();
  const dialect = useWarehouseDialect();
  const [isOpen, setOpen] = useState(false);
  const { entities } = useIdeConfig().data;

  const entity = entities.find((x) => x.name === entity_name);

  if (entity === undefined) {
    throw new Error(`Entity "${entity_name}" not found`);
  }

  const { assumptions, valid } = useAssumptionBuilder({
    candidates,
    dialect,
    entityIdType: entity.idType,
  });

  const mutate = useMutation({
    mutationFn: async () => {
      // const primaryKeyColumnName = assumptions.find(
      //   (x) => x.isPrimary
      // )!.column_name;
      // const assume = Object.fromEntries(
      //   assumptions
      //     .filter((col) => col.isEnabled && !col.isPrimary)
      //     .map((col) => [col.column_name, col.tyParse._unsafeUnwrap()])
      // );

      // await t.config.upsertAssumptions({
      //   entityUuid: entity.uuid,
      //   tableName: table_name,
      //   tableSchema: table_schema,
      //   primaryKeyColumnName,
      //   assume,
      // });

      // toast.success(`Assumptions for "${entity_name}" set!`);
      // setOpen(false);

      throw new Error('TODO');
    },
  });

  return (
    <>
      <Button
        text="Set Assumptions"
        theme="primary"
        icon="lock-closed"
        onClick={() => setOpen((x) => !x)}
      />
      <Modal open={isOpen} onOpenChange={setOpen} priority="low" center={false}>
        <div className="min-w-[800px] w-4/5 p-4 flex flex-col space-y-8">
          <div>
            <H3 className={CODE_STYLE_CLASS}>
              "{table_schema}"."{table_name}"
            </H3>
          </div>
          <div className="flex flex-col space-y-4">
            <AssumptionEditor assumptions={assumptions} />
          </div>
          <div className="flex flex-row justify-end w-full">
            {mutate.isPending ? (
              <Loading.Spinner />
            ) : (
              <Button
                disabled={!valid}
                theme="primary"
                text="submit"
                icon="check"
                onClick={() => mutate.mutate()}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

const AssumptionEditor: React.FC<{ assumptions: Assumption[] }> = ({
  assumptions,
}) => {
  return (
    <table className="table-auto border-separate border-spacing-2 border border-slate-500 border-spacing-2">
      <thead>
        <tr>
          <th>
            <span role="img" aria-label="lock" className="select-none">
              🔒
            </span>
          </th>
          <th>Name</th>
          <th>Warehouse Data Type</th>
          <th>Nullable</th>
          <th>Era Type</th>
          <th>Primary</th>
        </tr>
      </thead>
      <tbody>
        {assumptions.map((col) => {
          return (
            <tr
              className={classNames(col.isEnabled ? '' : 'opacity-35')}
              key={col.column_name}
            >
              <td>
                <input
                  type="checkbox"
                  checked={col.isEnabled}
                  onChange={() => col.setEnabled(!col.isEnabled)}
                />
              </td>
              <td className="font-mono border border-slate-600 p-2 bg-gray-100">
                {col.column_name}
              </td>
              <td className="font-mono border border-slate-600 p-2 bg-gray-100">
                {col.data_type}
              </td>
              <td className="font-mono border border-slate-600 p-2 bg-gray-100">
                {col.is_nullable.toString()}
              </td>
              <td>
                <div className="font-mono flex flex-row items-center space-x-2">
                  <input
                    className={
                      col.isPrimary ? 'border border-slate-600 bg-gray-100' : ''
                    }
                    disabled={!col.isEnabled || col.isPrimary}
                    type="text"
                    value={col.eraTy}
                    onChange={(x) => col.setEraTy(x.target.value)}
                  />
                  <div className="select-none">
                    {col.tyParse.match(
                      () => '✅',
                      () => '❌'
                    )}
                  </div>
                </div>
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={col.isPrimary}
                  disabled={!col.isEnabled}
                  onChange={() => col.setPrimary(!col.isPrimary)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
