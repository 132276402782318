import { Constant, If, Sum, Today, Yesterday } from '@cotera/era';
import { OrdersAdjusted } from './base';
import { mkRfmAnalytics } from '../apps/rfm-analytics';
import { rfmRounder } from '../apps/rfm-rounder';
import { RfmAssignment } from './rfm-assignment';
import { MaterializationConfig } from '@cotera/sdk/core';

const analyticsTimePeriod = [90];

export const HistoricalCustomerRfm = mkRfmAnalytics({
  Orders: OrdersAdjusted,
  rfmDays: 365,
  monthAverages: true,
  analyticsTimePeriod,
}).andThen(RfmAssignment);

export const TodaysCustomerRFM = mkRfmAnalytics({
  Orders: OrdersAdjusted,
  rfmDays: 1,
  monthAverages: true,
  analyticsTimePeriod,
}).andThen(RfmAssignment);

export const RfmLifecycleSummary = HistoricalCustomerRfm.select((t) => ({
  DATE: t.attr('TODAY').dateTrunc('month'),
  CUSTOMER_ID: t.attr('__COTERA_CUSTOMER_ID'),
  SEGMENT: t.attr('RFM_CATEGORY'),
  SEGMENT_NUM: t.attr('RFM_NUM'),
  TOTAL_SPEND: t.attr('TOTAL_SPEND').cast('float'),
  ITEM_COUNT: t.attr('ITEM_COUNT').cast('int'),
  DAYS_SINCE_LAST_ORDER: t.attr('DAYS_SINCE_LAST_ORDER'),
}))
  .where((t) =>
    t.attr('DATE').gte(Today().dateSub('days', 365).dateTrunc('month'))
  )
  .groupBy((t) => t.pick('DATE', 'CUSTOMER_ID', 'SEGMENT', 'SEGMENT_NUM'))
  .select((t) => ({
    ...t.group(),
    DAYS_IN_SEGMENT: Sum(1),
    WEIGHTING_FACTOR: Sum(1).div(
      If(t.attr('DATE').neq(Yesterday().dateTrunc('month')), {
        then: 3,
        else: rfmRounder(Yesterday()),
      })
    ),
    ITEM_COUNT: Sum(t.attr('ITEM_COUNT')),
    TOTAL_SPEND: Sum(t.attr('TOTAL_SPEND')),
    ORDER_COUNT: Sum(
      If(t.attr('ITEM_COUNT').gt(0), { then: 1, else: 0 })
    ).coalesce(0),
  }))
  .groupBy((t) => t.pick('DATE', 'SEGMENT', 'SEGMENT_NUM'))
  .select((t) => ({
    ...t.group(),
    COUNT: Sum(t.attr('WEIGHTING_FACTOR')).round(0).cast('int'),
    TOTAL_ITEM_COUNT: Sum(t.attr('ITEM_COUNT')),
    AVG_ITEMS: Sum(
      t.attr('WEIGHTING_FACTOR').cast('float').mul(t.attr('ITEM_COUNT'))
    )
      .safeDiv(Sum(t.attr('WEIGHTING_FACTOR')))
      .coalesce(Constant(0).cast('float')),
    TOTAL_SPEND: Sum(t.attr('TOTAL_SPEND')),
    AVG_SPEND: Sum(
      t.attr('WEIGHTING_FACTOR').cast('float').mul(t.attr('TOTAL_SPEND'))
    )
      .safeDiv(Sum(t.attr('WEIGHTING_FACTOR')))
      .coalesce(Constant(0).cast('float')),
    TOTAL_ORDER_COUNT: Sum(t.attr('ORDER_COUNT')),
    AVG_ORDER_COUNT: Sum(
      t.attr('WEIGHTING_FACTOR').cast('float').mul(t.attr('ORDER_COUNT'))
    )
      .safeDiv(Sum(t.attr('WEIGHTING_FACTOR')))
      .coalesce(Constant(0).cast('float')),
  }))
  .select((t) => t.renameWith((name) => name.toLowerCase()));

export const RfmLifectyleMaterialization = MaterializationConfig.fromConf({
  viewName: 'RfmLifecycleSummary',
  source: RfmLifecycleSummary,
});
