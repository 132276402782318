import { Loading, ResultBoundary, Title } from '@cotera/client/app/components';
import { jsonToCsv } from '@cotera/client/app/components/utils';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { useTenantedQueryKey } from '@cotera/client/app/hooks/use-tenanted-query-key';
import { useTenantedClient, useWhoami } from '@cotera/client/app/stores/org';
import { Relation } from '@cotera/era';
import { useSuspenseQuery } from '@tanstack/react-query';
import Markdown from 'markdown-to-jsx';
import { Suspense } from 'react';

type LLMSummaryProps = {
  rel: Relation;
  prompt: string;
  title?: string;
  model?: string;
};

const useLlmSummary = ({ prompt, rel, model }: LLMSummaryProps) => {
  const orgId = useWhoami((s) => s.org.id);

  const queryKey = useTenantedQueryKey([
    orgId,
    'llm-summary',
    prompt,
    rel.sqlHash(),
  ]);

  const client = useTenantedClient();
  const results = useDuckDBQuery({ rel: rel.limit(1000) });

  return useSuspenseQuery({
    queryFn: async () => {
      const result = await client.llm.completion({
        orgId,
        model: model ?? 'o1-mini',
        maxTokens: 5000,
        messages: [
          {
            role: 'user',
            content: `
              ${prompt}
              You will be provided data that is string of csv data. The first row is the headers.

              You MUST return your analysis markdown format.
            `,
          },
          {
            role: 'user',
            content:
              'here is the data in csv format. The first row is the headers.',
          },
          {
            role: 'user',
            content: trimCsvToMaxLength(
              await jsonToCsv(results.data.data.toArray())
            ),
          },
        ],
      });
      return result.map((x) =>
        x.completion.replace('```markdown', '').replace('```', '')
      );
    },
    queryKey,
  });
};

function trimCsvToMaxLength(csvString: string, maxLength = 80000) {
  // Split the CSV string into lines
  const lines = csvString.split('\n');

  // Keep removing lines from the end until the string length is under maxLength
  while (lines.join('\n').length > maxLength) {
    lines.pop(); // Remove the last line
  }

  // Return the trimmed CSV string
  return lines.join('\n');
}

export const LLMSummary: React.FC<LLMSummaryProps> = ({
  prompt,
  rel,
  title,
}) => {
  return (
    <Suspense
      fallback={
        <div className="flex flex-col">
          {title && <Title type="section" title={title} className="mb-4" />}
          <Loading.Shimmer className="h-[10px] w-[20%] mb-2" />
          <Loading.Shimmer className="h-[10px] w-[70%] mb-2" />
          <Loading.Shimmer className="h-[10px] w-[60%] mb-2" />
          <Loading.Shimmer className="h-[10px] w-[65%] mb-4" />
          <Loading.Shimmer className="h-[10px] w-[20%] mb-2" />
          <Loading.Shimmer className="h-[10px] w-[50%] mb-2" />
        </div>
      }
    >
      <LLMSummaryData prompt={prompt} rel={rel} />
    </Suspense>
  );
};

export const LLMSummaryData: React.FC<LLMSummaryProps> = ({ prompt, rel }) => {
  const { data: summary } = useLlmSummary({ prompt, rel });

  return (
    <ResultBoundary result={summary}>
      {(res) => (
        <Markdown
          options={{
            createElement(tag, props, children) {
              const Tag = tag as any;
              return (
                <Tag {...props} style={{ marginBottom: '1rem' }}>
                  {children}
                </Tag>
              );
            },
          }}
        >
          {res}
        </Markdown>
      )}
    </ResultBoundary>
  );
};
