import { Link } from 'react-router-dom';
import { Layout } from '../../layout';
import { useIdeConfig } from '@cotera/client/app/hooks/entities';
import { classNames, globalTheme } from '@cotera/client/app/components/utils';
import { Card } from '@cotera/client/app/components/headless';
import { Text } from '@cotera/client/app/components/ui';
import { extractLetters } from '@cotera/client/app/etc';

const theme = globalTheme.theme('random');

export const EntitiesListPage: React.FC = () => {
  const {
    data: { entities },
  } = useIdeConfig();

  return (
    <Layout>
      <div className="min-h-full flex flex-col items-center w-full">
        <div className="mx-auto max-w-7xl p-8 w-full">
          <h2 className="text-lg font-semibold leading-6 text-standard-text">
            Select an entity
          </h2>
          <Text.Caption className="mt-1 text-sm mb-6">
            Get started by choosing an entity to explore.
          </Text.Caption>
          <ul className="grid grid-cols-2">
            {entities.map((entity, i) => {
              return (
                <li
                  key={i}
                  className={classNames(
                    'relative flex items-start space-x-3 mr-4'
                  )}
                >
                  <Card.Container className="flex items-center w-full justify-between">
                    <Link to={`/entities/${entity.name}`} className="flex-1">
                      <div className="flex flex-row h-full w-full">
                        <div
                          className="bg-muted-background w-[40px] text-sm rounded-l-md flex items-center justify-center px-2 text-alt-text border-r mr-4"
                          style={{
                            color: theme.forLabel(entity.name).color,
                          }}
                        >
                          {extractLetters(entity.name)}
                        </div>
                        <div className="min-h-[32px] flex flex-col items-center justify-center text-sm mr-4 py-4">
                          {entity.name}
                        </div>
                      </div>
                    </Link>
                  </Card.Container>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Layout>
  );
};
