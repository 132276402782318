import {
  Center,
  Divider,
  Loading,
  Section,
  Title,
} from '@cotera/client/app/components/ui';
import { Layout } from '@cotera/client/app/layout';
import { Suspense, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDetectedFeatures, useQueryKey, useVersions } from '../hooks';
import { Assert } from '@cotera/utilities';
import { DetectedFeatures } from './detected-features';
import { actions, DraftProvider } from './context';
import { applyMiddleware, withHistory } from '@cotera/client/app/etc';
import { withTopicsSync } from '../utils';
import { useTenantedClient } from '@cotera/client/app/stores/org';
import { useQueryClient } from '@tanstack/react-query';
import { Filters, FormState } from './filters';

/**
 * here we want to show
 * - the topic name
 * - the version name
 * - the topic description
 * - a sample of the messages that make up the topic
 * - a view that shows the features that make up the topic
 * - the ability to add new features to the topic
 * - a chart breakdown of the features that make up the topic
 */
export const TopicPage: React.FC = () => {
  return (
    <Layout>
      <Suspense
        fallback={
          <Center>
            <Loading.Dots />
          </Center>
        }
      >
        <View />
      </Suspense>
    </Layout>
  );
};

const View: React.FC = () => {
  const {
    topicName,
    uddId,
    version: versionName,
  } = useParams() as {
    topicName: string;
    uddId: string;
    entityName: string;
    version: string;
  };

  const client = useTenantedClient();
  const queryClient = useQueryClient();
  const { data: topicVersions } = useVersions(uddId);
  const version = topicVersions.find((x) => x.version === versionName);

  Assert.assert(version !== undefined, 'Version not found');

  const queryKey = useQueryKey({
    uddId,
    versionId: version.id,
  });

  const { topics, features } = useDetectedFeatures({
    uddId,
    assigned: 'all',
    versionId: version.id,
  });
  const [form, setForm] = useState<FormState>({
    search: '',
    filter: 'assigned',
  });

  const topic = topics.find((x) => x.name === topicName);

  Assert.assert(topic !== undefined, 'Topic not found');

  return (
    <DraftProvider
      key={version.id}
      state={{
        pop: () => {},
        history: [],
        topics: [topic],
        features,
        focusedFeature: null,
        synced: 'synced',
      }}
      actions={(set, get) => {
        const enhancedSet = applyMiddleware(
          [
            withHistory,
            withTopicsSync(client, version.id, () => {
              void queryClient.invalidateQueries({ queryKey });
            }),
          ],
          set,
          get
        );
        return actions(enhancedSet, get);
      }}
    >
      {' '}
      <Section direction="vertical" className="w-full" top={false}>
        <div className="flex w-full items-center justify-between  mb-4">
          <div className="flex w-full items-center justify-between">
            <Title
              title={topic.name}
              type="title"
              className="mr-2"
              subtitle={topic.id}
            />
          </div>
        </div>
        <Divider className="mb-4" />
        <div className="flex w-full h-[calc(100%-54px)]">
          <div className="flex flex-col w-2/3 h-full">
            <Filters value={form} onChange={setForm} />
            <Suspense fallback={<Loading.Dots />}>
              <DetectedFeatures topicId={topic.id} query={form} />
            </Suspense>
          </div>
          <div className="flex flex-col w-1/2 ml-4">TODO</div>
        </div>
      </Section>
    </DraftProvider>
  );
};
