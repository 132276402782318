import React from 'react';
import { getCommonPinningStyles, makeActionsColumn } from '../utils';
import { Cell } from './cell';
import { RowActions } from './row-actions';
import {
  Button,
  Divider,
  Title,
  Text,
  Icon,
} from '@cotera/client/app/components/ui';
import { classNames } from '../../utils';
import { useDataGridStore } from '../context';
import { Inputs } from '../../forms';
import { TC, Ty } from '@cotera/era';
import { FFI } from '@cotera/sdk/core';
import { useFuzzySearch } from '@cotera/client/app/hooks/use-fuzzy-search';

export const SlideOver: React.FC<{
  attributes: Record<string, Ty.ExtendedAttributeType>;
}> = ({ attributes }) => {
  const show = useDataGridStore((s) => s.showActionsColumn);
  const hiddenColumns = useDataGridStore((s) => s.hiddenColumns);
  const showColumn = useDataGridStore((s) => s.actions.showColumn);
  const hideColumn = useDataGridStore((s) => s.actions.hideColumn);
  const toggleShow = useDataGridStore((s) => s.actions.toggleActionsColumn);
  const [searchText, setSearch] = React.useState('');
  const search = useFuzzySearch(
    Object.keys(attributes).map((x) => ({
      key: x,
    })),
    ['key']
  );
  const searchedKeys = search(searchText).map((x) => x.key);
  const columns = Object.entries(attributes).filter(([attr]) =>
    searchedKeys.includes(attr)
  );

  const hiddenAttributes = columns.filter(([attr]) =>
    hiddenColumns.includes(attr)
  );
  const visibleAttributes = columns.filter(
    ([attr]) => !hiddenColumns.includes(attr)
  );

  return (
    <>
      <div
        onClick={() => {
          toggleShow();
        }}
        className={classNames(
          show ? 'opacity-60 blur-md' : 'z-[-1] opacity-0',
          'blur-md w-full h-full absolute top-0 right-0 h-full border-l border-divider z-10 transition-all duration-300 ease-in-out bg-white'
        )}
      />
      <div
        className={classNames(
          show ? 'w-[250px] opacity-100' : 'w-0 opacity-0',
          'overflow-x-hidden overflow-y-scroll absolute top-0 right-0 h-full bg-white border-l border-divider z-50 transition-all duration-300 ease-in-out'
        )}
      >
        <div className="flex flex-col justify-center px-4 w-[250px]">
          <div className="flex justify-between items-center mb-2">
            <Title type="section">Manage Data Grid</Title>
            <Button
              inline
              icon="close"
              onClick={() => {
                toggleShow();
              }}
            />
          </div>
          <Inputs.Text
            placeholder="Search for a property"
            icon="search"
            value={searchText}
            onChange={setSearch}
          />
          <Divider className="mb-4 mt-4" />
          <Title type="label">Shown in table</Title>
          {visibleAttributes.length === 0 && (
            <Text.Caption className="text-left mt-2">
              No attributes
            </Text.Caption>
          )}
          <ul className="mb-4">
            {visibleAttributes.map(([attr, ty]) => (
              <li key={attr} className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                  <AttrIcon ty={ty} />
                  <Text.Span className="overflow-ellipsis">{attr}</Text.Span>
                </div>
                <Button
                  small
                  inline
                  icon="eye"
                  onClick={() => {
                    hideColumn(attr);
                  }}
                />
              </li>
            ))}
          </ul>
          <Title type="label">Hidden from table</Title>
          {hiddenAttributes.length === 0 && (
            <Text.Caption className="text-left mt-2">
              No attributes
            </Text.Caption>
          )}
          <ul className="mb-4">
            {hiddenAttributes.map(([attr, ty]) => (
              <li key={attr} className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                  <AttrIcon ty={ty} />
                  <Text.Span className="overflow-ellipsis">{attr}</Text.Span>
                </div>
                <Button
                  small
                  inline
                  icon="eye-slash"
                  onClick={() => {
                    showColumn(attr);
                  }}
                />
              </li>
            ))}
          </ul>
          <Title type="label">Actions</Title>
        </div>
      </div>
    </>
  );
};

const Header: React.FC = () => {
  const toggleActionsColumn = useDataGridStore(
    (s) => s.actions.toggleActionsColumn
  );

  return (
    <>
      <th
        style={{
          ...getCommonPinningStyles(makeActionsColumn('right')),
        }}
        className="bg-white border-b border-l border-divider text-standard-text"
      >
        <div className="flex flex-col justify-center items-center">
          <Button
            overrides={{ height: 'h-fit', py: '' }}
            small
            compact
            inline
            icon="bars-3"
            onClick={() => {
              toggleActionsColumn();
            }}
          />
        </div>
      </th>
    </>
  );
};

const Body: React.FC = () => {
  return (
    <Cell
      style={getCommonPinningStyles(makeActionsColumn('right'))}
      column="__actions"
      className={'bg-white border-l'}
    >
      <RowActions />
    </Cell>
  );
};

export const ActionsColumn = {
  Header,
  Body,
};

const AttrIcon: React.FC<{ ty: Ty.ExtendedAttributeType }> = ({ ty }) => {
  if (FFI.isFFI(ty)) {
    return <Icon icon="sparkles" size="small" />;
  }

  if (TC.isId(ty)) {
    return <Icon icon="key" size="small" />;
  }

  if (TC.isNumeric(ty)) {
    return <Icon icon="variable" size="small" />;
  }

  if (TC.isTimestamp(ty)) {
    return <Icon icon="calendar" size="small" />;
  }

  if (TC.isStringLike(ty)) {
    return <Icon icon="text" theme="muted" size="small" />;
  }

  if (TC.isBoolean(ty)) {
    return <Icon icon="check-circle" size="small" />;
  }

  return <span className="w-4 h-4">&nbsp;</span>;
};
