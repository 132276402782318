import { Relation, Ty } from '@cotera/era';
import { CellRenderer, Registry } from './types';
import { Cell } from './components/cell';
import React from 'react';
import { BaseHeader } from '../data-vis/data-grid/header';
import { Loading } from '@cotera/client/app/components/ui';
import { mapValues } from 'lodash';

type CellView = (
  column: string,
  ty: Ty.ExtendedAttributeType,
  value: unknown
) => React.ReactNode;
type HeaderView = (
  rel: Relation,
  column: string,
  ty: Ty.ExtendedAttributeType
) => React.ReactNode;
type LoadingCellView = (column: string) => React.ReactNode | null;

const DefaultLoadingCell: React.FC<{
  column: string;
}> = ({ column }) => (
  <Cell column={column}>
    <Loading.Shimmer className={'h-full w-full'} />
  </Cell>
);

export class DataGridRegistry implements Registry {
  private readonly _registry: {
    matcher: (column: string, ty: Ty.ExtendedAttributeType) => boolean;
    header?: HeaderView;
    headerActions?: HeaderView[];
    cell?: CellView;
    loadingCell?: LoadingCellView;
  }[] = [];

  getHeader(
    rel: Relation,
    column: string,
    ty: Ty.ExtendedAttributeType
  ): React.ReactNode {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    return (
      view?.header?.(rel, column, ty) ??
      BaseHeader({
        attr: { name: column, ty, detailPages: {} },
        children: null,
      })
    );
  }

  getHeaderActions(
    rel: Relation,
    column: string,
    ty: Ty.ExtendedAttributeType
  ): React.ReactNode[] {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    return view?.headerActions?.map((v) => v(rel, column, ty)) ?? [];
  }

  getCell(column: string, ty: Ty.ExtendedAttributeType): CellRenderer {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    const renderer: CellRenderer = (props) =>
      view?.cell?.(props.column, props.ty, props.value) ??
      Cell({
        column: props.column,
        children: props.value as React.ReactNode,
      });

    return renderer;
  }

  getCellRenderers(rel: Relation): Record<string, CellRenderer> {
    return mapValues(rel.attributes, (ty, column) => {
      return this.getCell(column, ty);
    });
  }

  getLoadingCell(
    column: string,
    ty: Ty.ExtendedAttributeType
  ): React.ReactNode | null {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    return view?.loadingCell?.(column) ?? DefaultLoadingCell({ column });
  }

  add(
    rule: (column: string, ty: Ty.ExtendedAttributeType) => boolean,
    views: {
      cell?: CellView;
      header?: HeaderView;
      loadingCell?: LoadingCellView;
    }
  ) {
    this._registry.push({ matcher: rule, ...views });

    return this;
  }
}
