import { Ty } from '.';
import { Assert } from '../utils';
import _ from 'lodash';

export type Primitive = string | number | boolean | Date | null;

export type Scalar =
  | Primitive
  | { readonly [field: string]: Scalar }
  | readonly Scalar[];

export type PrimitiveTimeType = 'day' | 'month' | 'year' | 'timestamp';

export type PrimitiveAttributeType =
  | 'int'
  | 'float'
  | 'boolean'
  | 'string'
  | PrimitiveTimeType
  | 'super';

export type RangeType = {
  readonly k: 'range';
  readonly start: number;
  readonly end: number;
  readonly t: 'int';
};

export type IdType = {
  readonly k: 'id';
  readonly t: 'int' | 'string';
  readonly name: string;
};

export type StructType = {
  readonly k: 'struct';
  readonly fields: { readonly [name: string]: ExtendedAttributeType };
};

export type EnumType = {
  readonly k: 'enum';
  readonly t: readonly string[];
};

export type PrimitiveType = {
  readonly k: 'primitive';
  readonly t: PrimitiveAttributeType;
};

export type ArrayType = {
  readonly k: 'array';
  readonly t: ExtendedAttributeType;
};

export type RecordType = {
  readonly k: 'record';
  readonly t: ExtendedAttributeType;
};

export type AttributeType =
  | PrimitiveType
  | ArrayType
  | EnumType
  | RangeType
  | IdType
  | StructType
  | RecordType;

export type ExtendedAttributeType = {
  readonly ty: AttributeType;
  readonly nullable: boolean;
  readonly tags: readonly string[];
};

export type Shorthand =
  | PrimitiveAttributeType
  | AttributeType
  | { k: 'array'; t: Shorthand }
  | { k: 'struct'; fields: { readonly [name: string]: Shorthand } }
  | { ty: PrimitiveAttributeType; nullable?: boolean; tags?: readonly string[] }
  | ExtendedAttributeType;

export type Row = { readonly [name: string]: Scalar };

export const PRIMITIVE_ATTRIBUTE_TYPES = [
  'int',
  'float',
  'boolean',
  'string',
  'year',
  'month',
  'day',
  'timestamp',
  'super',
] as const;

export type ShorthandToTsTy<ShorhandTy extends Shorthand> =
  ShorhandTy extends 'string'
    ? string
    : ShorhandTy extends 'boolean'
    ? boolean
    : ShorhandTy extends 'timestamp'
    ? Date
    : ShorhandTy extends 'int'
    ? number
    : ShorhandTy extends 'float'
    ? number
    : Scalar;

export const shorthandToTy = (
  shorthand: Shorthand,
  opts?: { readonly nullable?: boolean }
): ExtendedAttributeType => {
  if (typeof shorthand === 'string') {
    return { ty: { k: 'primitive', t: shorthand }, nullable: true, tags: [] };
  }

  if ('k' in shorthand) {
    if (shorthand.k === 'primitive') {
      return { ty: shorthand, nullable: true, tags: [] };
    }

    if (shorthand.k === 'struct') {
      const fields = _.mapValues(shorthand.fields, (x) => shorthandToTy(x));
      return {
        ty: { k: 'struct', fields },
        nullable: true,
        tags: [],
      };
    } else if (shorthand.k === 'array') {
      return {
        ty: { k: 'array', t: shorthandToTy(shorthand.t) },
        nullable: true,
        tags: [],
      };
    } else if (shorthand.k === 'enum') {
      return {
        ty: { k: 'enum', t: Object.freeze(shorthand.t) },
        nullable: true,
        tags: [],
      };
    } else if (shorthand.k === 'id') {
      return { ty: shorthand, nullable: true, tags: [] };
    } else if (shorthand.k === 'range') {
      return { ty: shorthand, nullable: true, tags: [] };
    } else if (shorthand.k === 'record') {
      return { ty: shorthand, nullable: true, tags: [] };
    } else {
      return Assert.unreachable(shorthand);
    }
  }

  const { ty, nullable = true } = shorthand;

  return {
    tags: shorthand.tags ?? [],
    ty: typeof ty === 'string' ? { k: 'primitive' as const, t: ty } : ty,
    nullable: opts?.nullable !== undefined ? opts.nullable : nullable,
  };
};

export const ty = shorthandToTy;

export const displayTy = (shorthand: Shorthand): string => {
  const { ty, nullable, tags } = shorthandToTy(shorthand);

  let inner: string;

  const { k } = ty;

  switch (k) {
    case 'primitive':
      inner = ty.t;
      break;
    case 'array':
      inner = `ARRAY(${displayTy(ty.t)})`;
      break;
    case 'enum':
      inner = `ENUM(${ty.t.map((x) => `'${x}'`).join(', ')})`;
      break;
    case 'range':
      inner = `RANGE(${ty.start}, ${ty.end})`;
      break;
    case 'id':
      inner = `ID_${ty.t.toUpperCase()}('${ty.name}')`;
      break;
    case 'record':
      inner = `RECORD(${displayTy(ty.t)})`;
      break;
    case 'struct':
      inner = `STRUCT({ ${Object.entries(ty.fields)
        .map(([name, ty]) => `${escapeStructKey(name)}: ${displayTy(ty)}`)
        .join(', ')} })`;
      break;
  }

  const displayTags =
    tags.length > 0
      ? ` ${tags
          .map((tag) => `@${tag.match(/^[a-zA-Z_-]*$/) ? tag : `"${tag}"`}`)
          .join(' ')}`
      : '';
  const displayNullable = nullable ? '' : '!';
  return `${inner}${displayNullable}${displayTags}`;
};

export const makeNullable = (ty: Shorthand): ExtendedAttributeType => ({
  ...shorthandToTy(ty),
  nullable: true,
});
export const makeNotNullable = (ty: Shorthand): ExtendedAttributeType => ({
  ...shorthandToTy(ty),
  nullable: false,
});

export const nn = makeNotNullable;

export const structOf = (fields: {
  readonly [name: string]: Shorthand;
}): ExtendedAttributeType =>
  shorthandToTy({
    k: 'struct',
    fields: _.mapValues(fields, (ty) => shorthandToTy(ty)),
  });

export const arrayOf = (shorthand: Shorthand): AttributeType =>
  shorthandToTy({ k: 'array', t: shorthand }).ty;

export const enumOf = (variants: readonly string[]): EnumType => ({
  k: 'enum',
  t: variants,
});

export const tag = (
  shorthand: Shorthand,
  newTags: string[]
): ExtendedAttributeType => {
  const { ty, nullable, tags } = shorthandToTy(shorthand);
  return {
    ty,
    nullable,
    tags: Object.freeze(_.uniq([...tags, ...newTags]).sort()),
  };
};

export const EMBEDDING = tag(arrayOf('float'), ['EMBEDDING']);
export const CARD_LT_1000 = tag('string', ['CARD_LT_1000']);

export const intId = (name: string): IdType => ({
  name,
  k: 'id',
  t: 'int',
});

export const stringId = (name: string): IdType => ({
  name,
  k: 'id',
  t: 'string',
});

export const iid = intId;
export const sid = stringId;

export const e = enumOf;
export const s = structOf;
export const a = arrayOf;

export const isArrayType = (attr: Shorthand): boolean => {
  const { ty } = shorthandToTy(attr);
  return ty.k === 'array';
};

export const isStructType = (attr: Shorthand): boolean => {
  const { ty } = shorthandToTy(attr);
  return ty.k === 'struct';
};

export const isIdentifier = (attr: Shorthand): attr is IdType => {
  const { ty } = shorthandToTy(attr);
  return ty.k === 'id';
};

export const isSuperType = (attr: Shorthand): boolean => {
  const { ty } = shorthandToTy(attr);
  return ty.k === 'primitive' && ty.t === 'super';
};

export const hasTag = (
  ty: Shorthand,
  tags: string | readonly string[]
): boolean => {
  const ety = shorthandToTy(ty);
  const needed = typeof tags === 'string' ? [tags] : tags;
  return needed.every((tag) => ety.tags.includes(tag));
};

export const isEnum = (ty: Shorthand): boolean =>
  shorthandToTy(ty).ty.k === 'enum';

export const range = (start: number, end: number): RangeType => ({
  k: 'range',
  t: 'int',
  start,
  end,
});

export const record = (t: Ty.Shorthand): RecordType => ({
  k: 'record',
  t: shorthandToTy(t),
});

const escapeStructKey = (key: string): string =>
  key.match(/^[a-zA-Z]+$/) ? key : `"${key}"`;
